import { API, authClient } from "../index";

export const getVisits = () => {
	return authClient().get(`${API}/visit?getDisabled=true`);
};

export const getVisitById = (id) => {
	return authClient().get(`${API}/visit/${id}`);
};

export const getVisitsByPatientId = (patientId) => {
	return authClient().get(`${API}/visit/patient/${patientId}`);
};

export const getLastVisit = (patientId) => {
	return authClient().get(`${API}/visit/patient/${patientId}/last-visit`)
};

export const getMonthVisits = (month) => {
	return authClient().get(`${API}/visit/month/${month}`)
}

export const deleteVisit = (id) => {
	return authClient().delete(`${API}/visit/${id}`);
};

export const postVisit = async (visit) => {
	return authClient().post(`${API}/visit`, visit);
};

export const updateVisit = async (id, visit) => {
	return authClient().put(`${API}/visit/${id}`, visit);
};

export const changeStatusVisit = async (id, active) => {
	return authClient().put(`${API}/visit/change-status/${id}`, {
		active,
	});
};

export const countVisits = () => {
	return authClient().get(`${API}/visit/count/`)
}