import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import { combineDateAndTime, formatDate, formatTime } from "./helpers";
import { getMonthVisits } from "../../../api/visit";
import { alertError } from "../../../utils/logger";
import { getMonthUserCompetitions } from "../../../api/userCompetitions";
import { useSkeleton } from "../../hooks/useSkeleton";
import { getMonthTasks } from "../../../api/task";

export default function FullCalendarView({
  setDate, setSelectedEvent, visits, setVisits, setOpenDialogs, setTypeEvent, 
  tasks, setTasks, initialDate, setInitialDate
}) {

  const [events, setEvents] = useState(0);
  const [competitions, setCompetitions] = useState(null);
  const [initialView, setInitialView] = useState("dayGridMonth");
  const [month, setMonth] = useState(null);

  const [first, setFirst] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (month) fetchDataCalendar(month);
  }, [month]);

  useEffect(() => {
    const fetchData = async () => {
      const { events } = await getInfoDate(visits, competitions, tasks);
      setEvents(events);
    }

    if (visits?.length > 0 || competitions?.length > 0 || tasks?.length > 0) fetchData();
  }, [visits, competitions, tasks])

  useEffect(() => {
    customizeHeaderWeek();
    setRefresh(false);
  }, [refresh]);

  async function fetchDataCalendar(month) {
    await getMonthVisits(month)
        .then((res) => {
          setVisits(res.data);
        })
        .catch((error) => {
          if (error?.request?.status !== 404) {
            alertError({
              error: error,
              customMessage: "There was a an error trying to get month visits"
          })} else {
            setVisits(null);
          }
        })

    await getMonthTasks(month)
        .then((res) => {
          setTasks(res.data)
        })
        .catch((error) => {
          if (error?.request?.status !== 404) {
            alertError({
              error: error,
              customMessage: "There was a an error trying to get month tasks"
          })} else setTasks(null);
        })

    await getMonthUserCompetitions(month)
        .then((res) => {
          setCompetitions(res.data);
          disableLoadingData();
        })
        .catch((error) => {
          if (error?.request?.status !== 404) {
            alertError({
              error: error,
              customMessage: "There was a an error trying to get month competitions"
          })} else {
            disableLoadingData();
            setCompetitions(null);
          }
        })
  }

  function customizeHeaderWeek() {
    const headers = document.querySelectorAll('.fc .fc-col-header-cell');
    
    headers.forEach((header) => {
      header.style.backgroundColor = 'black';
      header.style.textAlign = 'center';
      header.style.padding = '0';
      header.style.margin = '0';
      header.style.justifyContent = 'center';
      header.style.alignItems = 'center';
    });
  }
  

  async function getInfoDate(visits, competitions, tasks) {
    let data = [];
  
    for (let i = 0; i < visits?.length; ++i) {
      const actV = visits[i];
      const startDate = getFormattedDate(actV?.visitDate);

      data.push({
        start: startDate,
        title: '',
        extendedProps: {
          details: formatVisitDetails(actV),
          visit: {...actV, user: actV?.user?._id}
        }
      })
    }

    for (let i = 0; i < competitions?.length; ++i) {
      const userCompAct = competitions[i];

      for (let j = 0; j < userCompAct?.competitions?.length; ++j) {
        const compAct = userCompAct.competitions[j];
        const startDate = getFormattedDate(compAct?.date);

        data.push({
          start: startDate,
          title: '',
          extendedProps: {
            details: formatCompetitionDetails(compAct, userCompAct?.userId),
            competition: {...compAct, user: userCompAct?.userId?._id}
          }
        })
      }
    }

    for (let i = 0; i < tasks?.length; ++i) {
      const actT = tasks[i];
      const startDate = getFormattedDate(actT?.date);

      data.push({
        start: startDate,
        title: '',
        extendedProps: {
          details: formatTaskDetails(actT),
          task: actT
        }
      })
    }

    return { events: data };
  }

  function formatVisitDetails(visit) {
    const time = getTime(visit?.visitDate);
    let details = '';
    details += `<div>
    <strong>Pacient</strong>: ${visit?.user?.name} ${visit?.user?.surname}
    </div> 
    <div>
    <strong>Type</strong>: ${visit?.visitType?.fullName?.es}
    </div>
    <div>
    <strong>Hour</strong>: ${time}
    </div>`
    return details;
  }

  function formatCompetitionDetails(competition, user) {
    let details = '';
    details += `<div>
    <strong>Pacient</strong>: ${user?.name} ${user?.surname}
    </div> 
    <div>
    <strong>Competition</strong>: ${competition?.competitionName} ${competition?.finished? '✔️' : ``}
    </div>`
    return details;
  }

  function formatTaskDetails(task) {
    let details = '';
    details += `<div>
    <strong>Title</strong>: ${task?.title}
    </div> 
    <div>
    <strong>Description</strong>: ${task?.description}
    </div>`
    return details;
  }

  function getFirstDay(dateStr) {
    const newDate = new Date(dateStr);
    const day = newDate.getDate();

    if (day >= 23 && day <= 31) {
      newDate.setMonth(newDate.getMonth() + 1);
      newDate.setDate(1);

      if (day === 31) newDate.setMonth(newDate.getMonth() - 1)
    }

    setMonth(getFormattedDate(newDate));
  }

  const getFormattedDate = (date) => {
    const localDate = new Date(date);
    
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(localDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const getTime = (datte) => {
    const date = new Date(datte);

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }

  const getMonthDate = (date) => {
    const localDate = new Date(date);
    
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 2).padStart(2, '0');
    const day = String(1).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
  function dateContent(info) {
    const { details, competition, task } = info.event.extendedProps;
    
    if (competition) return (
      <div style={{
        backgroundColor: '#FD9D90',
        color: 'black',
        border: 'none',
        padding: '5px',
        whiteSpace: 'normal',
        overflowWrap: 'break-word',
        borderRadius: '5px',
      }}>
        <div dangerouslySetInnerHTML={{__html: details}} />
      </div>
    ); else if (task) return (
      <div style={{
        backgroundColor: '#FFFF6D',
        color: 'black',
        border: 'none',
        padding: '5px',
        whiteSpace: 'normal',
        overflowWrap: 'break-word',
        borderRadius: '5px',
      }}>
        <div dangerouslySetInnerHTML={{__html: details}} />
      </div>
    );
    else return (
        <div style={{
          backgroundColor: '#ABF5FF',
          color: 'black',
          padding: '5px',
          borderRadius: '5px'
        }}>
          <div dangerouslySetInnerHTML={{__html: details}} />
        </div>
      )
  }

  const handleDate = (event) => {
		setInitialDate(event.target.value);
    setFirst(true);
		setRefresh(true);
	}
  
  function dayCellContent(arg) {
    const formattedDate = getFormattedDate(arg.date);

    return (
      <div >
        <div >{arg.dayNumberText}</div>
        <div style={{ fontSize: '12px', fontWeight: 'bold', color: 'black', alignContent: 'center' }}>
          
        </div>
      </div>
    );
  }

  if (isLoadingData) return <>
    <input
      style={{ 
        marginLeft: "150px", 
        marginTop: "-1.5px", 
        position: 'absolute',
        height: '35px'
      }}
        type="date"
        id="date-selector"
        value={initialDate}
        onChange={handleDate}
    />
    <FullCalendar
      plugins={[interactionPlugin, dayGridPlugin]}
      initialView={initialView}
      headerToolbar={{
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,dayGridWeek"
      }}
      firstDay={1}
      initialDate={initialDate}
      height={'auto'}
      contentHeight={'auto'}
      datesSet={(info) => { 
        getFirstDay(info.startStr);
      }}
    />
  </>
  else return (
    <>
      {!refresh && (
        <>
        <input
          style={{ 
            marginLeft: "150px", 
            marginTop: "-1.5px", 
            position: 'absolute',
            height: '35px'
          }}
            type="date"
            id="date-selector"
            value={initialDate}
            onChange={handleDate}
          />
          <FullCalendar
            plugins={[interactionPlugin, dayGridPlugin]}
            initialView={initialView}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,dayGridWeek"
            }}
            firstDay={1}
            initialDate={initialDate}
            editable={true}
            selectable={true}
            events={events}
            height={'auto'}
            contentHeight={'auto'}
            eventContent={(info) => dateContent(info)}
            dateClick={(info) => {
              // console.log(info, "HOLA")
              setDate(info.dateStr)
              setSelectedEvent(null);
              setOpenDialogs(true);
            }}
            eventClick={(info) => {
              // console.log(info.event.extendedProps.visit, "HOLA")
              if (info?.event?.extendedProps?.visit) {
                setSelectedEvent(info.event.extendedProps.visit);
                setTypeEvent(1);
                setOpenDialogs(true);
              } else if (info?.event?.extendedProps?.task) {
                setSelectedEvent(info.event.extendedProps.task);
                setTypeEvent(2);
                setOpenDialogs(true);
              }
            }}
            datesSet={(info) => { 
              setInitialView(info.view.type)
              getFirstDay(info.startStr);
              if (!first) setInitialDate(getMonthDate(info.start));
              else setFirst(false);
              customizeHeaderWeek();
            }}
            dayCellContent={dayCellContent}
          />
        </>
        )}
    </>
  );
}