import React from 'react';
import { calcsIsakTest, losedWeight, sweatRate } from './mesuresCalcs';

export function getBodyWeightsData(tests) {
    let data = [];

    for (let i = 0; i < tests?.length; ++i) {
        let elem = {};
        const actT = tests[i];

        elem._id = actT?._id;
        elem.weight = actT?.weight;
        elem.date = actT?.date;

        data.push(elem);
    }

    return data;
}

export function getAbdominalWeightsData(tests) {
    let data = [];

    for (let i = 0; i < tests?.length; ++i) {
        let elem = {};
        const actT = tests[i];

        elem._id = actT?._id;
        elem.date = actT?.date;
        elem.maxAbdominalGrith = actT?.maxAbdominalGrith;
        elem.minAbdominalGrith = actT?.minAbdominalGrith;

        data.push(elem);
    }

    return data;
}

export function getSweatTestsData(tests) {
    let data = [];

    for (let i = 0; i < tests?.length; ++i) {
        let elem = {};
        const actT = tests[i];

        elem._id = actT?._id;
        elem.date = actT?.date;
        elem.temp = actT?.averageTemperature;
        elem.intensity = actT?.trainingIntensity;

        const losedW = losedWeight(actT.preTrainingWeight, actT.postTrainingWeight, 
            actT.intakeWeight, actT.urinations, actT.depositions);
        elem.sweatRate = sweatRate(losedW, actT.trainingDuration);

        data.push(elem);
    }

    return data;
}

export function getIsakTestsData(tests) {
    let data = [];

    for (let i = 0; i < tests?.length; ++i) {
        let elem = {};
        const actT = tests[i];

        elem._id = actT?._id;
        elem.date = actT?.date;
        elem.weight = actT?.weight;

        const calcs = calcsIsakTest(actT);
        elem.sumSix = calcs.sumSpecificPlecs;
        elem.sumEight = calcs.sumPlecs;
        elem.fatMass = calcs.zFatMass;
        elem.fatFree = calcs.zFatFreeMass;

        data.push(elem);
    }

    return data;
}