import { Button, TextField, createMuiTheme, } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader } from '../../../../../_metronic/_partials/controls';
import { 
    calcsIsakTest,
    dcCalcOptions,
} from '../../../../../utils/measures/mesuresCalcs';
import OnlyNumberReadDec from '../../../../components/textFields/OnlyNumberReadDec';
import Plot from 'react-plotly.js';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default function ViewPatientIsakTest(props) {
    const { patient, isakTest, setIsakTest, setOpen } = props;

    const pdfRef1 = useRef();
    const pdfRef2 = useRef();
    const pdfRef3 = useRef();
    const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

    useEffect(() => {        
        let newIsakTest = {...isakTest}
        newIsakTest.date = getDateFormat(isakTest);
        newIsakTest = refreshCalcs(newIsakTest);
        if (patient?.gender) newIsakTest.gender = patient.gender;
        setIsakTest(calcsIsakTest({...newIsakTest}));
    }, [])

    const generatePDF = () => {
        const page1 = pdfRef1.current;
        const page2 = pdfRef2.current;
        const page3 = pdfRef3.current;
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210;
        let position = 0;

        html2canvas(page1, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        
            pdf.addPage();
        
            return html2canvas(page2, { scale: 2 });
        }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        
            pdf.addPage();
        
            return html2canvas(page3, { scale: 2 });
        }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        
            pdf.save(`IsakTestOf${dateFormatter(isakTest.date)}.pdf`);
        });
    };

    function dateFormatter(date) {
        const today = new Date(date);
        const y = today.getFullYear();
        const m = today.getMonth() + 1;
        const d = today.getDate();

        const formattedM = m < 10 ? `0${m}` : m;
        const formattedD = d < 10 ? `0${d}` : d;
        return `${y}-${formattedM}-${formattedD}`
    }

    function floatFormatter(value) {
        const floatValue = parseFloat(value);
        if (Number.isInteger(floatValue)) return floatValue;
        else if (Math.round(floatValue*10)/10 === floatValue) return floatValue.toFixed(1);
        else return floatValue.toFixed(2);
    }

    const TextCalcField = React.useCallback(({title, element, number, data, type}) => {
        if (!data || !data[element]) return(<></>)
        else if ((number === "total" || number === "zValue") && !data[element][0][number]) return(
            <TextField 
                id={`${element}`}
                label={title}
                value={"Put the first ones"}
                InputLabelProps={{
                shrink: true
                }}
                inputProps={{readOnly: true}}
                margin="normal"
                variant="standard"
                className='readonly'
                type='text'
                required
            />
        )
        return(
            <TextField 
                id={`${element}`}
                label={title}
                value={floatFormatter(data[element][0][number])}
                InputLabelProps={{
                shrink: true
                }}
                inputProps={{readOnly: true}}
                margin="normal"
                variant="standard"
                className='readonly'
                type='text'
                required
            />
        )
    }, [])

    const SomatotipPlot = () => {
        const test = isakTest;
        const ect = parseFloat(test?.ectomorphic);
        const mes = parseFloat(test?.mesomorphic);
        const end = parseFloat(test?.endomorphic);

        if (ect && mes && end) {
            const x = ect - end;
            const y = 2*mes - (end + ect);
            const intX = parseInt(Math.abs(x))*2, intY = parseInt(Math.abs(y))*2;
            return (
                <Plot
                    data={[
                        {
                            type: 'scatter',
                            mode: 'markers',
                            x: [x],
                            y: [y],
                            marker: { size: 10, color: 'red' },
                            text: [`(${x.toFixed(1)}, ${y.toFixed(1)})`],
                            hoverinfo: 'text',
                        },
                    ]}
                    layout={{
                        width: 500,
                        height: 500,
                        title: 'Somatotip Graphic',
                        xaxis: {
                            range: [-intX, intX],
                            zeroline: true,
                            showgrid: true,
                            title: '',
                            nticks: 20
                        },
                        yaxis: {
                            range: [-intY, intY],
                            zeroline: true,
                            showgrid: false,
                            title: '',
                            nticks: 20
                        },
                        annotations: [
                            {
                                x: 0,
                                y: intY,
                                xref: 'x',
                                yref: 'y',
                                text: 'MESOMORPHIC',
                                showarrow: false,
                                font: {
                                size: 10,
                                color: 'black',
                                },
                                align: 'center',
                            },
                            {
                                x: -intX + intX/10, // Coordenadas para la anotación en la esquina inferior izquierda
                                y: -intY,
                                xref: 'x',
                                yref: 'y',
                                text: 'ENDOMORPHIC',
                                showarrow: false,
                                font: {
                                size: 10,
                                color: 'black',
                                },
                                align: 'left',
                            },
                            {
                                x: intX - intX/10, // Coordenadas para la anotación en la esquina inferior derecha
                                y: -intY,
                                xref: 'x',
                                yref: 'y',
                                text: 'ECTOMORPHIC',
                                showarrow: false,
                                font: {
                                size: 10,
                                color: 'black',
                                },
                                align: 'right',
                            },
                        ],
                    }}
                    config={{
                        staticPlot: false,
                        displayModeBar: false,
                        scrollZoom: false,
                        doubleClick: false,
                        displaylogo: false,
                    }}
                    />
            );
        }
        return (<></>);
    }

    const FoldPlecsPlot = () => {
        const test = isakTest;
        const triceps = parseFloat(test?.tricepsFold[0]?.total);
        const subcapular = parseFloat(test?.subcapularFold[0]?.total);
        const biceps = parseFloat(test?.bicepsFold[0]?.total);
        const iliacCrest = parseFloat(test?.iliacCrestFold[0]?.total)
        const supraspinal = parseFloat(test?.supraspinalFold[0]?.total);
        const abdominal = parseFloat(test?.abdominalFold[0]?.total);
        const thigh = parseFloat(test?.thighFold[0]?.total);
        const twin = parseFloat(test?.twinFold[0]?.total);

        if (triceps && subcapular && biceps && iliacCrest && 
        supraspinal && abdominal && thigh && twin) {
            
            const xNames = [
                "Triceps", "Subcapular", "Biceps", "Iliac Crest", "Supraspinal",
                "Abdominal", "Thigh", "Twin"
            ];   
            const yValues = [
                triceps, subcapular, biceps, iliacCrest, supraspinal,
                abdominal, thigh, twin
            ];

            return (
                <Plot
                    data={[
                        {
                            type: 'scatter',
                            mode: 'lines+markers',
                            x: xNames,
                            y: yValues,
                            marker: { size: 10, color: 'blue' },
                            line: { color: 'blue', width: 2 },
                            // name: '',
                            // showlegend: true,
                        },
                        // {
                        //     type: 'scatter',
                        //     mode: 'lines+markers',
                        //     x: ['Point A', 'Point B', 'Point C', 'Point D', 'Point E'],
                        //     y: [8, 10, 15, 12, 16],
                        //     marker: { size: 10, color: 'orange' },
                        //     line: { color: 'orange', width: 2 },
                        //     name: 'Data Series 2',
                        //     showlegend: true,
                        // }
                    ]}
                    layout={{
                        width: 600,
                        height: 400,
                        title: '',
                        xaxis: {
                            title: 'X Axis with Specific Names',
                            showgrid: false,
                        },
                        yaxis: {
                            title: 'Fold with (mm)',
                            showgrid: true,
                        },
                    }}
                    config={{
                        staticPlot: false,
                        displayModeBar: false,
                        scrollZoom: false,
                        doubleClick: false,
                        displaylogo: false,
                    }}
                />
            )
        }
        return (<></>)
    }

    const backgroundStyle = {backgroundColor: 'rgba(60, 60, 60, 0.4)'}

    function OpenInformation(title) {
        return (
            <>
                <br/>
                    <Row >
                        <Col sm={12} style={{marginTop: 12}}>
                            <h3 style={{fontWeight: 'bold',}}>{title}</h3>
                        </Col>
                    </Row>
                    <hr />
            </>
        )
    }

    function getDateFormat(test) {
        const testDate = new Date(test.date);
        const date = new Date(testDate.getTime() - testDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
        return date;
    }

    function refreshCalcs(test) {
        return test;
    }

    function onClose() {
        setOpen(false);
    }

    return (
        <Card>
            <CardHeader title="View isak test">
                <div className="mt-5">
                    <Button
                        onClick={() => {
                            onClose();
                        }}
                        variant="outlined"
                        style={{ marginRight: "20px" }}
                    >
                        Back
                    </Button>
                    <Button
                        onClick={generatePDF}
                        variant="outlined"
                        style={{ marginRight: "20px" }}
                    >
                        Generate inform
                    </Button>
                </div>
            </CardHeader>
            <CardBody>
                <div ref={pdfRef1} style={{ padding: 20, background: "white" }}>
                <CardBody>
                {/* INFORMACION BASICA */}
                <h1>ISAK TEST INFORM OF {patient?.name} {patient?.surname}</h1>
                <Row>
                    <Col sm={6}>
                        <TextField
                            id={"Date"}
                            label={"Date"}
                            value={isakTest["date"]}
                            InputLabelProps={{
                            shrink: true
                            }}
                            margin="normal"
                            variant="standard"
                            type='datetime-local'
                            required
                            inputProps={{readOnly: true}}
                            className='readonly'
                        />
                    </Col>
                    <Col sm={6}>
                        <TextField
                            id={"Observation"}
                            label={"Observation"}
                            value={isakTest["observations"]}
                            InputLabelProps={{
                            shrink: true
                            }}
                            margin="normal"
                            variant="standard"
                            type='text'
                            required
                            inputProps={{readOnly: true}}
                            className='readonly'
                        />
                    </Col>
                </Row>

                {OpenInformation("BASIC MEASURES")}
                <Row>
                    <Col sm={2}><OnlyNumberReadDec title={"Weight (kg)"} element={"weight"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><OnlyNumberReadDec title={"Height (cm)"} element={"height"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><OnlyNumberReadDec title={"Sitting height (cm)"} element={"sitHeight"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><OnlyNumberReadDec title={"IMC (kg/m2)"} element={"imc"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><OnlyNumberReadDec title={"Z Weight"} element={"zWeight"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><OnlyNumberReadDec title={"Z Sitting height"} element={"zSitHeight"} data={isakTest} type={"number"}/></Col>
                </Row>
                    

                {OpenInformation("SKIN FOLDS")}
                <Row>
                    <Col sm={2}><TextCalcField title={"Triceps measure 1"} element={"tricepsFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Triceps measure 2"} element={"tricepsFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Triceps error"} element={"tricepsFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Triceps measure 3"} element={"tricepsFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Triceps value"} element={"tricepsFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Z Triceps"} element={"tricepsFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                </Row>
                <Row>
                    <Col sm={2}><TextCalcField title={"Subcapular measure 1"} element={"subcapularFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Subcapular measure 2"} element={"subcapularFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Subcapular error"} element={"subcapularFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Subcapular measure 3"} element={"subcapularFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Subcapular value"} element={"subcapularFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Z Subcapular"} element={"subcapularFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                </Row>
                <Row>
                    <Col sm={2}><TextCalcField title={"Biceps measure 1"} element={"bicepsFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Biceps measure 2"} element={"bicepsFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Biceps error"} element={"bicepsFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Biceps measure 3"} element={"bicepsFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Biceps value"} element={"bicepsFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Z Biceps"} element={"subcapularFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                </Row>
                <Row>
                    <Col sm={2}><TextCalcField title={"Iliac crest measure 1"} element={"iliacCrestFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Iliac crest measure 2"} element={"iliacCrestFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Iliac error"} element={"iliacCrestFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Iliac crest measure 3"} element={"iliacCrestFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Iliac crest value"} element={"iliacCrestFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Z Iliac crest"} element={"iliacCrestFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                </Row>
                <Row>
                    <Col sm={2}><TextCalcField title={"Supraspinal measure 1"} element={"supraspinalFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Supraspinal measure 2"} element={"supraspinalFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Supraspinal error"} element={"supraspinalFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Supraspinal measure 3"} element={"supraspinalFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Supraspinal value"} element={"supraspinalFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Z Supraspinal"} element={"supraspinalFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                </Row>
                <Row>
                    <Col sm={2}><TextCalcField title={"Abdominal measure 1"} element={"abdominalFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Abdominal measure 2"} element={"abdominalFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Abdominal error"} element={"abdominalFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Abdominal measure 3"} element={"abdominalFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Abdominal value"} element={"abdominalFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Z Abdominal"} element={"abdominalFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                </Row>
                <Row>
                    <Col sm={2}><TextCalcField title={"Thigh measure 1"} element={"thighFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Thigh measure 2"} element={"thighFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Thigh error"} element={"thighFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Thigh measure 3"} element={"thighFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Thigh value"} element={"thighFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Z Thigh"} element={"thighFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                </Row>
                <Row>
                    <Col sm={2}><TextCalcField title={"Twin measure 1"} element={"twinFold"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Twin measure 2"} element={"twinFold"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Twin error"} element={"twinFold"} number={"error"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Twin measure 3"} element={"twinFold"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Twin value"} element={"twinFold"} number={"total"} data={isakTest} type={"number"}/></Col>
                    <Col sm={2}><TextCalcField title={"Z Twin"} element={"twinFold"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                </Row>
                <Row>
                    <Col sm={2} />
                    <Col sm={9}><FoldPlecsPlot /></Col>
                </Row>

                </CardBody>
                </div>
                <div ref={pdfRef2} style={{ padding: 20, background: "white" }}>
                    <CardBody>
                    {OpenInformation("PERIMETERS")}
                    <Row>
                        <Col sm={2}><TextCalcField title={"Relaxed Arm measure 1"} element={"relaxedArmPerimetral"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Relaxed Arm measure 2"} element={"relaxedArmPerimetral"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Relaxed Arm error"} element={"relaxedArmPerimetral"} number={"error"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Relaxed Arm measure 3"} element={"relaxedArmPerimetral"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Relaxed Arm value"} element={"relaxedArmPerimetral"} number={"total"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Z Relaxed arm"} element={"relaxedArmPerimetral"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                    </Row>
                    <Row>
                        <Col sm={2}><TextCalcField title={"Contracted Arm measure 1"} element={"contractedArmPerimetral"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Contracted Arm measure 2"} element={"contractedArmPerimetral"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Contracted Arm error"} element={"contractedArmPerimetral"} number={"error"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Contracted Arm measure 3"} element={"contractedArmPerimetral"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Contracted Arm value"} element={"contractedArmPerimetral"} number={"total"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Z Contracted arm"} element={"contractedArmPerimetral"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                    </Row>
                    <Row>
                        <Col sm={2}><TextCalcField title={"Waist measure 1"} element={"waistPerimetral"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Waist measure 2"} element={"waistPerimetral"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Waist error"} element={"waistPerimetral"} number={"error"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Waist measure 3"} element={"waistPerimetral"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Waist value"} element={"waistPerimetral"} number={"total"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Z Waist"} element={"waistPerimetral"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                    </Row>
                    <Row>
                        <Col sm={2}><TextCalcField title={"Hip measure 1"} element={"hipPerimetral"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Hip measure 2"} element={"hipPerimetral"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Hip error"} element={"hipPerimetral"} number={"error"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Hip measure 3"} element={"hipPerimetral"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Hip value"} element={"hipPerimetral"} number={"total"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Z Hip"} element={"hipPerimetral"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                    </Row>
                    <Row>
                        <Col sm={2}><TextCalcField title={"Thigh measure 1"} element={"thighPerimetral"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Thigh measure 2"} element={"thighPerimetral"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Thigh error"} element={"thighPerimetral"} number={"error"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Thigh measure 3"} element={"thighPerimetral"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Thigh value"} element={"thighPerimetral"} number={"total"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Z Thigh"} element={"thighPerimetral"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                    </Row>
                    <Row>
                        <Col sm={2}><TextCalcField title={"Twin measure 1"} element={"twinPerimetral"} number={"measure1"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Twin measure 2"} element={"twinPerimetral"} number={"measure2"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Twin error"} element={"twinPerimetral"} number={"error"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Twin measure 3"} element={"twinPerimetral"} number={"measure3"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Twin value"} element={"twinPerimetral"} number={"total"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><TextCalcField title={"Z Twin"} element={"twinPerimetral"} number={"zValue"} data={isakTest} type={"number"}/></Col>
                    </Row>
                    <Row>
                        <Col sm={4}><OnlyNumberReadDec title={"Corrected arm perimeter"} element={"correctedArm"} data={isakTest} type={"number"}/></Col>
                        <Col sm={4}><OnlyNumberReadDec title={"Corrected thigh perimeter"} element={"correctedThigh"} data={isakTest} type={"number"}/></Col>
                        <Col sm={4}><OnlyNumberReadDec title={"Corrected twin perimeter"} element={"correctedTwin"} data={isakTest} type={"number"}/></Col>
                    </Row>

                    {OpenInformation("DIAMETERS")}
                    <Row>
                        <Col sm={2}><OnlyNumberReadDec title={"Humerus (cm)"} element={"humerusDiameter"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><OnlyNumberReadDec title={"Z Humerus"} element={"zHumerus"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><OnlyNumberReadDec title={"Bistyloid (cm)"} element={"bistyloidDiameter"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><OnlyNumberReadDec title={"Z Bistyloid"} element={"zBistyloid"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><OnlyNumberReadDec title={"Femur (cm)"} element={"femurDiameter"} data={isakTest} type={"number"}/></Col>
                        <Col sm={2}><OnlyNumberReadDec title={"Z Femur"} element={"zFemur"} data={isakTest} type={"number"}/></Col>
                    </Row>
                    
                    {OpenInformation("OTHERS")}
                    <Row>
                        <Col sm={4}><OnlyNumberReadDec title={"6 PLECS (mm)"} element={"sumSpecificPlecs"} data={isakTest} type={"number"}/></Col>
                        <Col sm={4}><OnlyNumberReadDec title={"8 PLECS (mm)"} element={"sumPlecs"} data={isakTest} type={"number"}/></Col>
                        <Col sm={4}><OnlyNumberReadDec title={"Waist-Hip Index"} element={"waistHipIndex"} data={isakTest} type={"number"}/></Col>
                    </Row>

                    {OpenInformation("CORPORAL COMPOSATION")}
                    <Row>
                        <Col sm={4}>
                            <TextField
                                id={"DC calc type"}
                                label={"DC calc type"}
                                value={dcCalcOptions?.find(
                                    (x) => x._id === isakTest?.dcCalcId
                                )?.name || null}
                                InputLabelProps={{
                                shrink: true
                                }}
                                margin="normal"
                                variant="standard"
                                type='text'
                                required
                                inputProps={{readOnly: true}}
                                className='readonly'
                            />
                        </Col>
                        <Col sm={4}><OnlyNumberReadDec title={"DC (kg/m3)"} element={"dc"} data={isakTest} type={"number"}/></Col>
                        <Col sm={4}><OnlyNumberReadDec title={"Greix (%)"} element={"greix"} data={isakTest} type={"number"}/></Col>
                    </Row>
                    <Row>
                        <Col sm={4}><OnlyNumberReadDec title={"MG (kh)"} element={"mG"} data={isakTest} type={"number"}/></Col>
                        <Col sm={4}><OnlyNumberReadDec title={"MLG (kg)"} element={"mLG"} data={isakTest} type={"number"}/></Col>
                        <Col sm={4}><OnlyNumberReadDec title={"Z Fat mass"} element={"zFatMass"} data={isakTest} type={"number"}/></Col>
                    </Row>
                    </CardBody>
                </div>
                <div ref={pdfRef3} style={{ padding: 20, background: "white" }}>
                <CardBody>
                    {OpenInformation("SOMATOTIP")}
                    <Row>
                        <Col sm={4}><OnlyNumberReadDec title={"Endomorphic"} element={"endomorphic"} data={isakTest} type={"number"}/></Col>
                        <Col sm={4}><OnlyNumberReadDec title={"Mesomorphic"} element={"mesomorphic"} data={isakTest} type={"number"}/></Col>
                        <Col sm={4}><OnlyNumberReadDec title={"Ectomorphic"} element={"ectomorphic"} data={isakTest} type={"number"}/></Col>
                    </Row>
                    <Row>
                        <Col sm={3}></Col>
                        <Col sm={9}><SomatotipPlot /></Col>
                    </Row>
                </CardBody>
                </div>
            </CardBody>
            
        </Card>
    )
}