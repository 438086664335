import React, { useState, useEffect } from 'react'
import { deleteUserSweatTests, getUserSweatTestsByUserId, postUserSweatTests, updateUserSweatTests } from '../../../../../api/userSweatTests';
import { alertError, alertSuccess } from '../../../../../utils/logger';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { shallowEqual, useSelector } from 'react-redux';
import { Button, FormControlLabel, Tooltip } from "@material-ui/core";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
} from "../../../../components/tables/table";
import {
	Card,
	CardBody,
} from "../../../../../_metronic/_partials/controls";
import ViewPatientIsakTest from './ViewPatientIsakTest';
import { deleteUserIsakTests, getUserIsakTestsByUserId, postUserIsakTests, updateUserIsakTests } from '../../../../../api/userIsakTests';
import { Col, Row } from 'react-bootstrap';
import { formatFloat, formatInteger, TableHeader } from '../../../../../utils/helpers';
import EditBodyWeight from '../../../../components/dialogs/userMeasures/EditBodyWeight';
import EditAbdominalWeight from '../../../../components/dialogs/userMeasures/EditAbdominalWeight';
import { postUserBodyWeights, updateUserBodyWeights, getUserBodyWeightsByUserId, deleteUserBodyWeights } from '../../../../../api/userBodyWeights';
import { deleteUserAbdominalWeights, getUserAbdominalWeightsByUserId, postUserAbdominalWeights, updateUserAbdominalWeights } from '../../../../../api/userAbdominalWeights';
import { calcsIsakTest, losedWeight, sweatRate } from '../../../../../utils/measures/mesuresCalcs';
import Plot from 'react-plotly.js';
import EditIsakTest from '../../../../components/dialogs/userMeasures/EditIsakTest';
import EditSweatTest from '../../../../components/dialogs/userMeasures/EditSweatTest';
import { ExpandLess, ExpandMore, Visibility } from '@material-ui/icons';
import { getBodyWeightsData, getIsakTestsData, getAbdominalWeightsData, getSweatTestsData } from '../../../../../utils/measures/measuresUtils';
import FiltersCard from '../../../../components/filters/Filter';
import MyAutocomplete from '../../../../components/MyAutocomplete';

function getDateFormated(date) {
    const today = new Date(date);
    const y = today.getFullYear();
    const m = today.getMonth() + 1;
    const d = today.getDate();

    const formattedM = m < 10 ? `0${m}` : m;
    const formattedD = d < 10 ? `0${d}` : d;
    return `${y}-${formattedM}-${formattedD}`
}

function getActualDate() {
    return getDateFormated(new Date());
}

const initialFilters = {
	trainingIntensity: null
}

export default function EditPatientMeasures(props) {
    const { patientId, patient } = props;
    
    const [bodyWeights, setBodyWeights] = useState([]);    
    const [bodyPlotData, setBodyPlotData] = useState(null);
    const [bodyPlotDate, setBodyPlotDate] = useState(getActualDate());
    const [openEditBodyWeight, setOpenEditBodyWeight] = useState(false);
    const [selectedBodyWeight, setSelectedBodyWeight] = useState(null);
    const [bodyWeightInfo, setBodyWeightInfo] = useState(false);
    const [bodyWeightView, setBodyWeightView] = useState("daily");

    const [abdominalWeights, setAbdominalWeights] = useState([]);
    const [abdominalPlotData, setAbdominalPlotData] = useState(null);
    const [abdominalPlotDate, setAbdominalPlotDate] = useState(getActualDate());
    const [openEditAbdominalWeight, setOpenEditAbdominalWeight] = useState(false);
    const [selectedAbdominalWeight, setSelectedAbdominalWeight] = useState(null);
    const [abdominalWeightInfo, setAbdominalWeightInfo] = useState(false);
    const [abdominalWeightView, setAbdominalWeightView] = useState("daily");

    const [sweatTests, setSweatTests] = useState([]);
    const [sweatPlotData, setSweatPlotData] = useState([]);
    const [sweatPlotDate, setSweatPlotDate] = useState(getActualDate());
    const [openEditSweatTest, setOpenEditSweatTest] = useState(false);
    const [selectedSweatTest, setSelectedSweatTest] = useState(null);
    const [sweatTestInfo, setSweatTestInfo] = useState(false);
    const [sweatTestView, setSweatTestView] = useState("daily");
	const [collapsed, setCollapsed] = useState(true);
	const [filteredData, setFilteredData] = useState([]);
	const [filterOptions, setFilterOptions] = useState(initialFilters);
    
    const [isakTests, setIsakTests] = useState([]);
    const [isakPlotData, setIsakPlotData] = useState([]);
    const [isakPlotDate, setIsakPlotDate] = useState(getActualDate());
    const [openEditIsakTest, setOpenEditIsakTest] = useState(false);
    const [selectedIsakTest, setSelectedIsakTest] = useState(null);
    const [openViewIsakTest, setOpenViewIsakTest] = useState(false);
    const [isakTestInfo, setIsakTestInfo] = useState(false);
    const [isakTestView, setIsakTestView] = useState("daily");

    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
    const [refresh, setRefresh] = useState(false);
    const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

    useEffect(() => {
        if (!openEditBodyWeight) refreshBodyWeights();
    }, [openEditBodyWeight])

    
    useEffect(() => {
        if (!openEditAbdominalWeight) refreshAbdominalWeights();
    }, [openEditAbdominalWeight])

    useEffect(() => {
        if (!openEditSweatTest) refreshSweatTests();
    }, [openEditSweatTest])

    
    useEffect(() => {
        if (!openEditIsakTest) refreshIsakTests();
    }, [openEditIsakTest])

    useEffect(() => {
        if (bodyWeights) refreshBodyPlot(bodyWeights);
    }, [bodyPlotDate]);
    
    useEffect(() => {
        if (abdominalWeights) refreshAbdominalPlot(abdominalWeights);
    }, [abdominalPlotDate]);
    
    useEffect(() => {
        if (sweatTests || filteredData) 
            refreshSweatPlot(filteredData ? filteredData : sweatTests);
    }, [sweatPlotDate, filteredData]);
    
    useEffect(() => {
        if (isakTests) refreshIsakPlot(isakTests);
    }, [isakPlotDate]);

    useEffect(() => {
        setRefresh(false);
    }, [refresh])

    const zValues = ["Z1", "Z2", "Z3", "Z4", "Z5"];

    const bodyWeightColumns = [
        {
            dataField: "date",
            text: "date",
            formatter: dateFormatter
        },
        {
            dataField: "weight",
            text: "weight (kg)",
            align: 'center',
            headerAlign: 'center',
            formatter: formatFloat
        },
        {
            dataField: "_id",
            text: "",
            align: "right",
            formatter: bodyWeightbuttonFormatter
        }
    ]
    
    const abdominalWeightColumns = [
        {
            dataField: "date",
            text: "date",
            formatter: dateFormatter
        },
        {
            dataField: "maxAbdominalGrith",
            text: "Max Abdominal G",
            align: 'center',
            headerAlign: 'center',
            formatter: formatFloat
        },
        {
            dataField: "minAbdominalGrith",
            text: "Min Abdominal G",
            align: 'center',
            headerAlign: 'center',
            formatter: formatFloat
        },
        {
            dataField: "_id",
            text: "",
            align: "right",
            formatter: abdominalWeightbuttonFormatter
        }
    ]
    
    const sweatTestsColumns = [
        {
            dataField: "date",
            text: "date",
            formatter: dateFormatter
        },
        {
            dataField: "temp",
            text: "Temperature (ºC)",
            align: 'center',
            headerAlign: 'center',
            formatter: formatInteger
        },
        {
            dataField: "intensity",
            text: "Intensity",
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "sweatRate",
            text: "Sweat rate",
            align: 'center',
            headerAlign: 'center',
            formatter: formatFloat
        },
        {
            dataField: "_id",
            text: "",
            align: "right",
            formatter: sweatTestbuttonFormatter
        }
    ]
    
    const isakTestsColumns = [
        {
            dataField: "date",
            text: "date",
            formatter: dateFormatter
        },
        {
            dataField: "weight",
            text: "weight",
            formatter: formatFloat,
            align: 'center',
            headerAlign: 'center'
        },
        {
            dataField: "sumSix",
            text: "Sum of 6 skinfolds",
            formatter: formatFloat,
            align: 'center',
            headerAlign: 'center'
        },
        {
            dataField: "sumEight",
            text: "Sum of 8 skinfolds",
            align: 'center',
            headerAlign: 'center',
            formatter: formatFloat
        },
        {
            dataField: "fatMass",
            text: "Fat mass",
            align: 'center',
            headerAlign: 'center',
            formatter: formatFloat
        },
        {
            dataField: "fatFree",
            text: "Fat-free mass",
            align: 'center',
            headerAlign: 'center',
            formatter: formatFloat
        },
        {
            dataField: "_id",
            text: "",
            align: "right",
            formatter: isakTestbuttonFormatter,
            headerStyle: { width: "148px" }
        }
    ]
    
    function dateFormatter(cell) {
        const date = new Date(cell);
        
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
    
        return `${day}-${month}-${year}`;
    }
    
    function hourFormatter(cell) {
        const date = new Date(cell);
        const hour = date.getHours();
        const min = date.getMinutes();
        const sec = date.getSeconds();
        return `${hour}:${min}:${sec}`;
    }
    
    function bodyWeightbuttonFormatter(cell, row) {
        const elem = bodyWeights?.find((x) => x._id === cell);
    
        return (
            <>
                <Tooltip title="Edit">
                    <Button
                        style={buttonsStyle}
                        size="small"
                        onClick={() => {
                            setOpenEditBodyWeight(true);
                            setSelectedBodyWeight(elem);
                        }}
                    >
                        <EditIcon />
                    </Button>
                </Tooltip>
                {user?.role.includes("admin") && (
                    <>
                        <Tooltip title="Delete">
                            <Button
                                style={buttonsStyle}
                                size="small"
                                onClick={() => {
                                    setOpenConfirmDialog(4);
                                    setSelectedBodyWeight(elem);
                                }}
                            >
                                <DeleteIcon />
                            </Button>
                        </Tooltip>
                    </>
                )}
            </>
        )
    }
    
    function abdominalWeightbuttonFormatter(cell, row) {
        const elem = abdominalWeights?.find((x) => x._id === cell);
    
        return (
            <>
                <Tooltip title="Edit">
                    <Button
                        style={buttonsStyle}
                        size="small"
                        onClick={() => {
                            setOpenEditAbdominalWeight(true);
                            setSelectedAbdominalWeight(elem);
                        }}
                    >
                        <EditIcon />
                    </Button>
                </Tooltip>
                {user?.role.includes("admin") && (
                    <>
                        <Tooltip title="Delete">
                            <Button
                                style={buttonsStyle}
                                size="small"
                                onClick={() => {
                                    setOpenConfirmDialog(5);
                                    setSelectedAbdominalWeight(elem);
                                }}
                            >
                                <DeleteIcon />
                            </Button>
                        </Tooltip>
                    </>
                )}
            </>
        )
    }
    
    function sweatTestbuttonFormatter(cell, row) {
        const elem = sweatTests?.find((x) => x._id === cell);
    
        return (
            <>
                <Tooltip title="Edit">
                    <Button
                        style={buttonsStyle}
                        size="small"
                        onClick={() => {
                            setOpenEditSweatTest(true);
                            setSelectedSweatTest(elem);
                        }}
                    >
                        <EditIcon />
                    </Button>
                </Tooltip>
                {user?.role.includes("admin") && (
                    <>
                        <Tooltip title="Delete">
                            <Button
                                style={buttonsStyle}
                                size="small"
                                onClick={() => {
                                    setOpenConfirmDialog(2);
                                    setSelectedSweatTest(elem);
                                }}
                            >
                                <DeleteIcon />
                            </Button>
                        </Tooltip>
                    </>
                )}
            </>
        )
    }
    
    function isakTestbuttonFormatter(cell, row) {
        const elem = isakTests?.find((x) => x._id === cell);
    
        return (
            <>
                <Tooltip title="View">
                    <Button
                        style={buttonsStyle}
                        size="small"
                        onClick={() => {
                            setOpenViewIsakTest(true);
                            setSelectedIsakTest(elem);
                        }}
                    >
                        <Visibility />
                    </Button>
                </Tooltip>
                <Tooltip title="Edit">
                    <Button
                        style={buttonsStyle}
                        size="small"
                        onClick={() => {
                            setOpenEditIsakTest(true);
                            setSelectedIsakTest(elem);
                        }}
                    >
                        <EditIcon />
                    </Button>
                </Tooltip>
                {user?.role.includes("admin") && (
                    <>
                        <Tooltip title="Delete">
                            <Button
                                style={buttonsStyle}
                                size="small"
                                onClick={() => {
                                    setOpenConfirmDialog(3);
                                    setSelectedIsakTest(elem);
                                }}
                            >
                                <DeleteIcon />
                            </Button>
                        </Tooltip>
                    </>
                )}
            </>
        )
    }

    const months = [
        "jan", "feb", "mar", "apr", "may", "jun"
        , "jul", "aug", "sep", "oct", "nov", "dec"
    ]

    function normalizeDate(date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    function getPlotDates(data) {
        const today = new Date(data);
        const month = today.getMonth();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
        const lastDay = new Date(today);
        lastDay.setDate(lastDay.getDate() + 7);
        lastDay.setHours(23, 59, 59);
        
        const lastDayMonth = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7*8);
        
        const firstDayYear = new Date(today.getFullYear(), today.getMonth(), 1, 1);
        const lastDayYear = new Date(today.getFullYear() + 1, today.getMonth() - 1, 31, 23, 59, 59);
        
        const normalizedFirstDay = normalizeDate(firstDay);
        const normalizedLastDay = normalizeDate(lastDay);
        const normalizedLastDayMonth = normalizeDate(lastDayMonth)
        return { lastDayMonth, firstDayYear, lastDayYear, month, normalizedFirstDay, normalizedLastDay, normalizedLastDayMonth }
    }

    function refreshBodyPlot(data) {
        const { firstDayYear, lastDayYear, month, normalizedFirstDay, normalizedLastDay, normalizedLastDayMonth } = getPlotDates(bodyPlotDate);
        
        let weightByWeek = [null, null, null, null, null, null, null];
        
        let monthWeightSums = { 
            jan: 0, feb: 0, mar: 0, apr: 0, may: 0, jun: 0,
            jul: 0, aug: 0, sep: 0, oct: 0, nov: 0, dec: 0
        }
        let monthCount = {
            jan: 0, feb: 0, mar: 0, apr: 0, may: 0, jun: 0,
            jul: 0, aug: 0, sep: 0, oct: 0, nov: 0, dec: 0
        }

        let weekWeightSums = Array(8).fill(0);
        let weekNumber = Array(8).fill(0);
        
        for (let i = 0; i < data.length; ++i) {
            let actB = data[i];
            const weight = parseFloat(actB.weight);
            const date = new Date(actB.date);
            const normalizedDate = normalizeDate(date);

            //Monthly
            if (date >= firstDayYear && date <= lastDayYear) {
                const month = date.getMonth(); // 0 = January, 11 = December
                const monthKey = Object.keys(monthWeightSums)[month];
                monthWeightSums[monthKey] += weight;
                monthCount[monthKey]++;
            }
            
            //Weekly
            if (normalizedDate >= normalizedFirstDay && normalizedDate < normalizedLastDayMonth) {
                const diffInDays = Math.floor((normalizedDate - normalizedFirstDay) / (1000 * 60 * 60 * 24)); // Diferencia en días
                const weekIndex = Math.floor(diffInDays / 7);

                if (weekIndex >= 0 && weekIndex < 8) {
                    weekWeightSums[weekIndex] += weight;
                    weekNumber[weekIndex]++;
                }
            }

            //Daily

            if (normalizedDate >= normalizedFirstDay && normalizedDate <= normalizedLastDay) {
                const diffInDays = Math.floor((normalizedDate - normalizedFirstDay) / (1000 * 60 * 60 * 24));
                
                if (diffInDays >= 0 && diffInDays < 7) {
                    weightByWeek[diffInDays] = weight;
                }
            }
            // if (date < firstDay || date > lastDay) continue;
            // const diffInDays = Math.floor((date - firstDay) / (1000 * 60 * 60 * 24)); // Diferencia en días absolutos
            // const dayIndex = diffInDays % 7; // Ajustamos al índice semanal (0 a 6)
            // weightByWeek[dayIndex] = weight;
            // const day = date.getDay() - firstDay.getDay();
            // weightByWeek[day] = weight;
        }

        weekWeightSums = weekWeightSums.map((sum, index) => 
            weekNumber[index] > 0 ? sum / weekNumber[index] : null
        );
        
        let monthWeights = [];
        for (let i = 0; i < 12; ++i) {
            const actM = months[(month+i)%12]
            const count = monthCount[actM]
            monthWeights.push(count > 0 ? monthWeightSums[actM] / count : null);
        }
        // Object.keys(monthWeightSums).forEach((monthKey) => {
        //     const count = monthCount[monthKey];
        //     monthWeights.push(count > 0 ? monthWeightSums[monthKey] / count : null);
        // });

        setBodyPlotData({week: weightByWeek, month: weekWeightSums, year: monthWeights});
    }

    function refreshAbdominalPlot(data) {
        const { firstDayYear, lastDayYear, month, normalizedFirstDay, normalizedLastDay, normalizedLastDayMonth } = getPlotDates(abdominalPlotDate);

        const maxMin = {max: 0, min: 0}
        let monthWeightSums = { 
            jan: {...maxMin}, feb: {...maxMin}, mar: {...maxMin}, apr: {...maxMin}, may: {...maxMin}, jun: {...maxMin},
            jul: {...maxMin}, aug: {...maxMin}, sep: {...maxMin}, oct: {...maxMin}, nov: {...maxMin}, dec: {...maxMin}
        }
        let monthCount = {
            jan: 0, feb: 0, mar: 0, apr: 0, may: 0, jun: 0,
            jul: 0, aug: 0, sep: 0, oct: 0, nov: 0, dec: 0
        }
        function addMonthSums(measure, month) {
            monthWeightSums[month].max += parseFloat(measure.maxAbdominalGrith);
            monthWeightSums[month].min += parseFloat(measure.minAbdominalGrith);
        }

        let weekSums = Array(8).fill({...maxMin});
        let weekNumber = Array(8).fill(0);

        function addMaxMin(measure, week) {
            weekSums[week].max += parseFloat(measure.maxAbdominalGrith);
            weekSums[week].min += parseFloat(measure.minAbdominalGrith);
        }

        const empty = [null, null, null, null, null, null, null];
        let plotData = {
            maxAbdominalGrith: [...empty],
            minAbdominalGrith: [...empty]
        };

        for (let i = 0; i < data.length; ++i) {
            let actB = data[i];
            const date = new Date(actB.date);
            const normalizedDate = normalizeDate(date);

            //Monthly
            if (date >= firstDayYear && date <= lastDayYear) {
                const month = date.getMonth(); // 0 = January, 11 = December
                const monthKey = Object.keys(monthWeightSums)[month];
                addMonthSums(actB, monthKey);
                monthCount[monthKey]++;
            }

            //Weekly
            if (normalizedDate >= normalizedFirstDay && normalizedDate < normalizedLastDayMonth) {
                const diffInDays = Math.floor((normalizedDate - normalizedFirstDay) / (1000 * 60 * 60 * 24)); // Diferencia en días
                const weekIndex = Math.floor(diffInDays / 7);

                if (weekIndex >= 0 && weekIndex < 8) {
                    addMaxMin(actB, weekIndex);
                    weekNumber[weekIndex]++;
                }
            }

            //Daily
            if (normalizedDate >= normalizedFirstDay && normalizedDate <= normalizedLastDay) {
                const diffInDays = Math.floor((normalizedDate - normalizedFirstDay) / (1000 * 60 * 60 * 24));
                
                if (diffInDays >= 0 && diffInDays < 7) {
                    plotData.maxAbdominalGrith[diffInDays] = actB.maxAbdominalGrith;
                    plotData.minAbdominalGrith[diffInDays] = actB.minAbdominalGrith;
                }
            }
        }

        let monthMin = [], monthMax = [];
        weekSums.map((sum, index) => {
            const num = weekNumber[index];
            if (num > 0) {monthMin.push(sum.min / num); monthMax.push(sum.max / num) }
            else { monthMin.push(null); monthMax.push(null) };
        })

        let yearMax = [], yearMin = [];

        for (let i = 0; i < 12; ++i) {
            const actM = months[(month+i)%12]
            const count = monthCount[actM]
            if (count > 0) {
                yearMax.push(monthWeightSums[actM].max / count);
                yearMin.push(monthWeightSums[actM].min / count);
            }
            else {
                yearMax.push(null);
                yearMin.push(null);
            }
        }
        // Object.keys(monthWeightSums).forEach((monthKey) => {
        //     const count = monthCount[monthKey];
        //     if (count > 0) {
        //         yearMax.push(monthWeightSums[monthKey].max / count)
        //         yearMin.push(monthWeightSums[monthKey].min / count)
        //     }
        //     else {
        //         yearMax.push(null)
        //         yearMin.push(null)
        //     }
        // });

        setAbdominalPlotData({week: plotData, monthMax, monthMin, yearMin, yearMax});
    }

    function refreshSweatPlot(data) {

        function sortByNum(a, b) {
			if (a < b) return -1;
			if (b > a) return 1;
			return 0;
		}

        let sweatRates = [];
        
        for (let i = 0; i < data.length; ++i) {
            let actB = data[i];
            const losedW = losedWeight(actB.preTrainingWeight, actB.postTrainingWeight, 
                actB.intakeWeight, actB.urinations, actB.depositions);
            const sweatR = sweatRate(losedW, actB.trainingDuration);
            const rate = parseFloat(sweatR);

            sweatRates.push({sweatRate: rate, temperature: actB.averageTemperature});
        }

		sweatRates.sort(sortByNum)

        setSweatPlotData(sweatRates);
    }

    function refreshIsakPlot(data) {
        const { firstDayYear, lastDayYear, month, normalizedFirstDay, normalizedLastDay, normalizedLastDayMonth } = getPlotDates(isakPlotDate);

        const sums = {sum8: 0, sum6: 0}
        let monthWeightSums = { 
            jan: {...sums}, feb: {...sums}, mar: {...sums}, apr: {...sums}, may: {...sums}, jun: {...sums},
            jul: {...sums}, aug: {...sums}, sep: {...sums}, oct: {...sums}, nov: {...sums}, dec: {...sums}
        }
        let monthCount = {
            jan: 0, feb: 0, mar: 0, apr: 0, may: 0, jun: 0,
            jul: 0, aug: 0, sep: 0, oct: 0, nov: 0, dec: 0
        }
        function addMonthSums(measure, month) {
            const calcs = calcsIsakTest(measure);
            monthWeightSums[month].sum6 += parseFloat(calcs.sumSpecificPlecs);
            monthWeightSums[month].sum8 += parseFloat(calcs.sumPlecs);
        }

        let weekSums = Array(8).fill({...sums});
        let weekNumber = Array(8).fill(0);
        
        function addWeekSums(measure, week) {
            const calcs = calcsIsakTest(measure);
            weekSums[week].sum6 += parseFloat(calcs.sumSpecificPlecs);
            weekSums[week].sum8 += parseFloat(calcs.sumPlecs);
        }

        const empty = [null, null, null, null, null, null, null];
        let plotData = {
            sum6: [...empty],
            sum8: [...empty]
        };

        for (let i = 0; i < data.length; ++i) {
            let actB = data[i];
            const date = new Date(actB.date);
            const normalizedDate = normalizeDate(date);

            //Monthly
            if (date >= firstDayYear && date <= lastDayYear) {
                const month = date.getMonth(); // 0 = January, 11 = December
                const monthKey = Object.keys(monthWeightSums)[month];
                addMonthSums(actB, monthKey);
                monthCount[monthKey]++;
            }

            //Weekly
            if (normalizedDate >= normalizedFirstDay && normalizedDate < normalizedLastDayMonth) {
                const diffInDays = Math.floor((normalizedDate - normalizedFirstDay) / (1000 * 60 * 60 * 24)); // Diferencia en días
                const weekIndex = Math.floor(diffInDays / 7);

                if (weekIndex >= 0 && weekIndex < 8) {
                    addWeekSums(actB, weekIndex);
                    weekNumber[weekIndex]++;
                }
            }
            // if (date >= firstDayMonth && date < lastDayMonth) {
            //     const day = date.getDate();
            //     if (day < 8) {addWeekSums(actB, "week1"); weekNumber.week1++;}
            //     else if (day < 15) {addWeekSums(actB, "week2"); weekNumber.week2++;}
            //     else if (day < 22) {addWeekSums(actB, "week3"); weekNumber.week3++;}
            //     else if (day < 29) {addWeekSums(actB, "week4"); weekNumber.week4++;}
            //     else {addWeekSums(actB, "week5"); weekNumber.week5++;}
            // }

            //Daily
            if (normalizedDate >= normalizedFirstDay && normalizedDate <= normalizedLastDay) {
                const diffInDays = Math.floor((normalizedDate - normalizedFirstDay) / (1000 * 60 * 60 * 24));
                
                if (diffInDays >= 0 && diffInDays < 7) {
                    const calcs = calcsIsakTest(actB);
                    plotData.sum6[diffInDays] = calcs.sumSpecificPlecs;
                    plotData.sum8[diffInDays] = calcs.sumPlecs;
                }
            }
            // const dayOfWeek = date.getDay();
            // const adjustedDay = (dayOfWeek === 0 ? 6 : dayOfWeek - 1);
            // if (date < mondayDate || date > sundayDate) continue;
            // const calcs = calcsIsakTest(actB);
            // plotData.sum6[adjustedDay] = calcs.sumSpecificPlecs;
            // plotData.sum8[adjustedDay] = calcs.sumPlecs;
        }

        let month6 = [], month8 = [];
        weekSums.map((sum, index) => {
            const num = weekNumber[index];
            if (num > 0) {month8.push(sum.sum8 / num); month6.push(sum.sum6 / num) }
            else { month8.push(null); month6.push(null) };
        })

        // const month6 = [
        //     weekSums.week1.sum6 / (weekNumber.week1 || 1) || null,
        //     weekSums.week2.sum6 / (weekNumber.week2 || 1) || null,
        //     weekSums.week3.sum6 / (weekNumber.week3 || 1) || null,
        //     weekSums.week4.sum6 / (weekNumber.week4 || 1) || null,
        //     weekSums.week5.sum6 / (weekNumber.week5 || 1) || null
        // ]

        // const month8 = [
        //     weekSums.week1.sum8 / (weekNumber.week1 || 1) || null,
        //     weekSums.week2.sum8 / (weekNumber.week2 || 1) || null,
        //     weekSums.week3.sum8 / (weekNumber.week3 || 1) || null,
        //     weekSums.week4.sum8 / (weekNumber.week4 || 1) || null,
        //     weekSums.week5.sum8 / (weekNumber.week5 || 1) || null
        // ]

        let year6 = [], year8 = [];
        for (let i = 0; i < 12; ++i) {
            const actM = months[(month+i)%12]
            const count = monthCount[actM]
            if (count > 0) {
                year6.push(monthWeightSums[actM].sum6 / count)
                year8.push(monthWeightSums[actM].sum8 / count)
            }
            else {
                year6.push(null)
                year8.push(null)
            }
        }

        setIsakPlotData({week: plotData, month6, month8, year6, year8});
    }

    function refreshTest(getTest, setTest, type) {
        getTest(patientId)
            .then((res) => {
                if (res.status === 200) {
                    setTest(res.data);
                    switch (type) {
                        case "body weights": refreshBodyPlot(res.data); break;
                        case "abdominal weights": refreshAbdominalPlot(res.data); break;
                        case "sweat tests": setFilteredData(res.data); break;
                        case "isak tests": refreshIsakPlot(res.data); break;
                    }
                    setRefresh(true)
                }
            })
            .catch((error) => {
                if (error?.request?.status !== 404) {
                    alertError({
                        error: error,
                        customMessage: `Could not get ${type}`
                    })
                }
            })
    }

    function refreshBodyWeights() {
        refreshTest(getUserBodyWeightsByUserId, setBodyWeights, "body weights");
    }

    function refreshAbdominalWeights() {
        refreshTest(getUserAbdominalWeightsByUserId, setAbdominalWeights, "abdominal weights");
    }

    function refreshSweatTests() {
        refreshTest(getUserSweatTestsByUserId, setSweatTests, "sweat tests");
    }

    function refreshIsakTests() {
        refreshTest(getUserIsakTestsByUserId, setIsakTests, "isak tests");
    }

    async function saveTest(post, update, value, refreshTest, type) {
        const id = value?._id;
        if (!id) {
            const res = await post(value)
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: `Could not save ${type}`
                    })
                    return false;
                })
            if (res) {
                alertSuccess({
                    title: "Success!",
                    customMessage: `${type} saved succesfully.`
                })
                refreshTest();
                return true;
            }
            return false;
        }
        else {
            const res = await update(id, value)
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: `Could not update ${type}`
                    })
                })
            if (res) {
                alertSuccess({
                    title: "Success!",
                    customMessage: `${type} saved succesfully.`
                })
                refreshTest();
                return true;
            }
            return false;
        }
    }

    async function saveBodyWeight(bodyWeight) {
        return await saveTest(postUserBodyWeights, updateUserBodyWeights, 
            bodyWeight, refreshBodyWeights, "Body weight")
    }

    async function saveAbdominalWeight(abdominalWeight) {
        return await saveTest(postUserAbdominalWeights, updateUserAbdominalWeights, 
            abdominalWeight, refreshAbdominalWeights, "Abdominal weight")
    }

    async function saveSweatTest(sweatTest) {
        return await saveTest(postUserSweatTests, updateUserSweatTests, 
            sweatTest, refreshSweatTests, "Sweat test")
    }

    async function saveIsakTest(isakTest) {
        return await saveTest(postUserIsakTests, updateUserIsakTests, 
            isakTest, refreshIsakTests, "Isak test")
    }

    function deleteTest(deleteTestById, id, type, refresh) {
        deleteTestById(id)
        .then((res) => {
            if (res.status === 204 || res.status === 200) {
                alertSuccess({
                    title: "Deleted!",
                    customMessage:
                        `${type} deleted successfully.`,
                });
                refresh();
            }
        })
        .catch((error) => {
            alertError({
                error: error,
                customMessage:
                    `Could not delete ${type}.`,
            });
        });
    }

    function deleteBodyWeight(id) {
        deleteTest(deleteUserBodyWeights, id, "Body weight", refreshBodyWeights);
    }

    function deleteAbdominalWeight(id) {
        deleteTest(deleteUserAbdominalWeights, id, "Abdominal weight", refreshAbdominalWeights);
    }

    function deleteSweatTest(id) {
        deleteTest(deleteUserSweatTests, id, "Sweat test", refreshSweatTests);
    }

    function deleteIsakTest(id) {
        deleteTest(deleteUserIsakTests, id, "Isak test", refreshIsakTests);
    }

    const backgroundStyle = {backgroundColor: '#A7D971'}

    function OpenInformation (title, arrowTitle, info, setInfo)  {
        const arrowStyle = {color: '#E1F2CE'}
        return (
            <>
                <br/>
                    <Row style={backgroundStyle}>
                        <Col sm={11} style={{marginTop: 12}}>
                            <h5 style={{fontWeight: 'bold'}}>{title}</h5>
                        </Col>
                        <Col sm={1}>
                            <Tooltip title={arrowTitle}>
                            <Button
								size="small"
                                style={{backgroundColor: 'transparent', borderColor: 'transparent', marginLeft: 20}}
								onClick={() => setInfo(!info)}
							>
                                {info? (<ExpandLess style={arrowStyle}/>) : 
                                (<ExpandMore style={arrowStyle}/>)}
							</Button>
                            </Tooltip>
                        </Col>
                    </Row>
                <br/>
            </>
        )
    }

    function DialogDelete({type, number, id, deleteFunction}) {
        return (
            <ConfirmDialog
                title={
                    `Are you sure you want to delete this ${type}?`
                }
                open={openConfirmDialog === number}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                    deleteFunction(id)
                }}
            />
        )
    }

    const OnlyPlot = ({dataSet, xLabels, yTitle, xTitle, max}) => {
        return (
            <Plot
                style={{ width: '100%', display: "flex", justifyContent: 'flex-end' }}
                data={dataSet.map(({ data, name, color }) => ({
                    type: "scatter",
                    mode: "lines+markers",
                    x: xLabels,
                    y: data,
                    marker: { size: 8, color },
                    line: { width: 2, color, shape: "spline" },
                    name,
                    connectgaps: true,
                }))}
                layout={{
                    width: 600,
                    height: 400,
                    xaxis: {
                        showgrid: false,
                        title: xTitle || null
                    },
                    yaxis: {
                        title: yTitle,
                        showgrid: true,
                        range: max ? [0, max] : null
                    },
                    legend: {
                        orientation: "h",
                        x: 0.5,
                        xanchor: "center",
                        y: -0.3,
                    },
                }}
                config={{
                staticPlot: false,
                displayModeBar: false,
                scrollZoom: false,
                doubleClick: false,
                displaylogo: false,
                }}
            />
        )
    }

    const SweatPlot = () => {

        function getSets() {
            let dataSet = [], xLabels = [];

            for (let i = 0; i < sweatPlotData.length; ++i) {
                const actS = sweatPlotData[i];
                dataSet.push(actS.sweatRate);
                xLabels.push(actS.temperature);
            }

            return { 
                dataSet: [{ data: dataSet, name: "Sweat rate (L/min)", color: "red"}], 
                xLabels,
            };
        }

        const { dataSet, xLabels } = getSets();

        return (
            <OnlyPlot dataSet={dataSet} xLabels={xLabels} xTitle={"Temperature (ºC)"} yTitle={"Sweat rate (L/min)"}/>
        )
    }

    const MeasurePlot = ({yearSet, monthSet, weekSet, yTitle, date, setDate, view, setView}) => {
        // const [view, setView] = useState("daily");

        const handleDate = (event) => {
            setDate(event.target.value)
        }

        const moveDate = (left) => {
            const newDate = new Date(date);
            const sum = (left ? -1 : 1);
            switch (view) {
                case "daily": newDate.setDate(newDate.getDate() + sum); break;
                case "weekly": newDate.setDate(newDate.getDate() + sum * 7); break;
                case "monthly": newDate.setMonth(newDate.getMonth() + sum); break;
            }
            setDate(getDateFormated(newDate));
        }

        const getMonthlyLabels = (selectedDate) => {
            const actDate = new Date(selectedDate);
            const nextYearDate = new Date(actDate);
            nextYearDate.setFullYear(actDate.getFullYear() + 1);
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const month = actDate.getMonth();
            const year = actDate.getFullYear();
            const nextYear = nextYearDate.getFullYear();
            let newMonths = [];
            for (let i = 0; i < 12; ++i) 
                newMonths.push({
                    month: months[(month+i)%12], 
                    year: (i >= (12 - month)) ? nextYear : year
                }); 
            return newMonths.map((month) => `${month.month} ${month.year}`);
        };

        const getFirstDay = (selectedDate) => {
            const date = new Date(selectedDate);
            return date;
        }

        const getWeeklyLabels = (selectedDate) => {
            const firstD = getFirstDay(selectedDate);
            const labels = [];
            for (let i = 0; i < 8; i++) {
                const currentDay = new Date(firstD);
                currentDay.setDate(firstD.getDate() + i * 7);
    
                const formattedDate = currentDay.toLocaleDateString("es-ES", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                });
                labels.push(formattedDate);
            }
            console.log(labels)
            return labels;
        };
        
        const getWeekDates = (selectedDate) => {
            const startDate = new Date(selectedDate);
            // const dayOfWeek = startDate.getDay();
            // const startOfWeek = new Date(startDate);
            // startOfWeek.setDate(startDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)); // Ajusta para empezar el lunes
    
            const dates = [];
            for (let i = 0; i < 7; i++) {
                const currentDate = new Date(startDate);
                currentDate.setDate(startDate.getDate() + i);
    
                const formattedDate = currentDate.toLocaleDateString("es-ES", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                });
    
                dates.push(formattedDate);
            }
            return dates;
        }
    
        const xLabels = view === "daily" && date 
            ? getWeekDates(date) 
            : view === "weekly" && date 
            ? getWeeklyLabels(date) 
            : view === "monthly" && date
            ? getMonthlyLabels(date)
            : [];
        const dataSet = view === "daily" 
            ? weekSet 
            : view === "weekly" 
            ? monthSet 
            : yearSet;

        const backGroundButtons = {borderRadius: 10, 
            backgroundColor: '#1e2b37', height: '30px', marginLeft: 5,
            color: 'white', borderColor: 'transparent',};

        const styleButtons = {...backGroundButtons, width: '100px', marginLeft: 5,};
        const arrows = {...backGroundButtons, width: '40px', marginLeft: 1}

        const maxCalc = Math.max(...dataSet[0].data);
        const max = maxCalc + maxCalc/5;

        if ((!weekSet && view === "daily") || (!monthSet && view === "weekly")|| (!yearSet && view === "monthly"))
            return (<></>)
        return (
        <div>
            <div style={{ width: '100%', display: "flex", justifyContent: 'flex-end' }}>
                {date && 
                    <input
                    style={{ 
                        // right: "220px", 
                        // marginTop: "-5px", 
                        // position: 'absolute',
                        marginLeft: 5,
                        height: '35px'
                    }}
                        type="date"
                        id="date-selector"
                        value={date}
                        onChange={handleDate}
                    />
                }
                <button onClick={() => moveDate(true)} style={{...arrows, marginLeft: 8}}>
                {'<'}
                </button>
                <button onClick={() => moveDate(false)} style={{...arrows}}>
                {'>'}
                </button>
                <button onClick={() => setView("daily")} disabled={view === "daily"} style={{...styleButtons, opacity: `${view === "daily" ? "0.3" : "1"}`}}>
                Daily
                </button>
                <button onClick={() => setView("weekly")} disabled={view === "weekly"} style={{...styleButtons, opacity: `${view === "weekly" ? "0.3" : "1"}`}}>
                Weekly
                </button>
                <button onClick={() => setView("monthly")} disabled={view === "monthly"} style={{...styleButtons, opacity: `${view === "monthly" ? "0.3" : "1"}`}}>
                Monthly
                </button>
            </div>
            <OnlyPlot dataSet={dataSet} xLabels={xLabels} yTitle={yTitle} max={max}/>
        </div>
        );
    };

    const handleSearch = async () => {
		if (!sweatTests.length) return;
		
        const filter = sweatTests.filter(item => {
			let filter = true;
			if (filterOptions.trainingIntensity) filter = (filterOptions.trainingIntensity === item?.trainingIntensity);
			if (filter) return item;
			return false;
		});
		setFilteredData(filter)
        refreshSweatPlot(filter);
	}

    const handleClearFilters = () => {
		setFilterOptions({
			...initialFilters,
			trainingIntensity: null,
		});
		setRefresh(true);
	}

    const renderFiltersContent = () => {
		return <>
			<br/>
			<FormControlLabel 
				control={
                    <MyAutocomplete 
                        options={  zValues }
                        getOptionLabel={(option) => option}
                        value={ zValues.find((x) => x === filterOptions?.trainingIntensity) || null }
                        onChange={(event, selected) => {
                            setFilterOptions({
                                ...filterOptions,
                                trainingIntensity: selected
                            })
                        }}
                        placeholder="Select training intensity"
						style={{flex: 1, marginLeft: '20px', marginTop: '16px'}}
                    />
					}
				style={{width: "300px"}}
			/>
		</>
	}

    if (openViewIsakTest) return(
        <ViewPatientIsakTest 
        patientId={patientId}
        patient={patient}
        isakTest={selectedIsakTest}
        setIsakTest={setSelectedIsakTest}
        setOpen={setOpenViewIsakTest}
        />
    )
    return (
        <Card>
            <DialogDelete type="sweat test" number={2} id={selectedSweatTest?._id} deleteFunction={deleteSweatTest}/>
            <DialogDelete type="isak test" number={3} id={selectedIsakTest?._id} deleteFunction={deleteIsakTest}/>
            <DialogDelete type="body weight" number={4} id={selectedBodyWeight?._id} deleteFunction={deleteBodyWeight}/>
            <DialogDelete type="abdominal grith" number={5} id={selectedAbdominalWeight?._id} deleteFunction={deleteAbdominalWeight}/>

            <CardBody>
                {OpenInformation("BODY WEIGHTS", "Open body weights", bodyWeightInfo, setBodyWeightInfo)}
                {bodyWeightInfo && (
                    <>
                    <Row>
                        <Col>
                            <TableHeader 
                                title="Body weights"
                                onClick={() => {
                                    setOpenEditBodyWeight(true);
                                    setSelectedBodyWeight(null);
                                }}
                                numberCol={2} 
                            />
                            {!refresh && bodyWeights?.length > 0 && <Table data={getBodyWeightsData(bodyWeights)} columns={bodyWeightColumns}/>}
                        </Col>
                        {bodyWeights?.length > 0 &&
                        <Col>
                            <MeasurePlot 
                                yearSet={[{data: bodyPlotData?.year, name: "", color: "blue"}]}
                                monthSet={[{data: bodyPlotData?.month, name: "", color: "blue"}]}
                                weekSet={[{data: bodyPlotData?.week, name: "", color: "blue"}]} 
                                yTitle={"Weight (Kg)"} 
                                date={bodyPlotDate}
                                setDate={setBodyPlotDate}
                                view={bodyWeightView} 
                                setView={setBodyWeightView}
                            />
                        </Col>
                        }
                    </Row>
                    <EditBodyWeight
                        open={openEditBodyWeight}
                        setOpen={setOpenEditBodyWeight} 
                        data={selectedBodyWeight}
                        patientId={patientId}
                        onSave={async (bodyWeight) => {
                            return await saveBodyWeight(bodyWeight)
                        }}
                    />
                    </>
                )}
                
                <br />
                {OpenInformation("ISAK TESTS", "Open isak tests", isakTestInfo, setIsakTestInfo)}
                {isakTestInfo && ( 
                    <>
                    <Row>
                        <Col>
                            <TableHeader 
                                title="ISAK1 Anthropometry"
                                onClick={() => {
                                    setOpenEditIsakTest(true);
                                    setSelectedIsakTest(null);
                                }}
                                numberCol={2} 
                            />
                            {!refresh && isakTests?.length > 0 && <Table data={getIsakTestsData(isakTests)} columns={isakTestsColumns}/>}
                        </Col>
                        {isakTests?.length > 0 && 
                        <Col>
                            <MeasurePlot 
                            yearSet={[
                                {data: isakPlotData?.year8, name: "Sum 8 skinfolds", color: "green"},
                                {data: isakPlotData?.year6, name: "Sum 6 skinfolds", color: "red"},
                            ]}
                            monthSet={[
                                {data: isakPlotData?.month8, name: "Sum 8 skinfolds", color: "green"},
                                {data: isakPlotData?.month6, name: "Sum 6 skinfolds", color: "red"},
                            ]}
                            weekSet={[
                                { data: isakPlotData?.week?.sum8, name: "Sum 8 skinfolds", color: "green", },
                                { data: isakPlotData?.week?.sum6, name: "Sum 6 skinfolds", color: "red", },
                            ]} 
                            yTitle={"Sums of skinfolds"} 
                            date={isakPlotDate}
                            setDate={setIsakPlotDate}
                            view={isakTestView}
                            setView={setIsakTestView}
                            />
                        </Col>
                        }
                    </Row>
                    { openEditIsakTest &&
                        <EditIsakTest 
                        patientId={patientId}
                        patient={patient}
                        data={selectedIsakTest}
                        setOpen={setOpenEditIsakTest}
                        open={openEditIsakTest}
                        onSave={async (isakTest) => {
                            return await saveIsakTest(isakTest)
                        }}
                        />
                    }
                    </>
                )}

                <br />
                {OpenInformation("ABDOMINAL GRITHS", "Open abdominal griths", abdominalWeightInfo, setAbdominalWeightInfo)}
                {abdominalWeightInfo && (
                    <>
                    <Row>
                        <Col>
                            <TableHeader 
                                title="Abdominal griths"
                                onClick={() => {
                                    setOpenEditAbdominalWeight(true);
                                    setSelectedAbdominalWeight(null);
                                }}
                                numberCol={2} 
                            />
                            {!refresh && abdominalWeights?.length > 0 && <Table data={getAbdominalWeightsData(abdominalWeights)} columns={abdominalWeightColumns}/>}
                        </Col>
                        {abdominalWeights?.length > 0 &&
                        <Col>
                            <MeasurePlot 
                            yearSet={[
                                {data: abdominalPlotData?.yearMax, name: "Max Abdominal G", color: "red"},
                                {data: abdominalPlotData?.yearMin, name: "Min Abdominal G", color: "green"},
                            ]}
                            monthSet={[
                                {data: abdominalPlotData?.monthMax, name: "Max Abdominal G", color: "red"},
                                {data: abdominalPlotData?.monthMin, name: "Min Abdominal G", color: "green"},
                            ]}
                            weekSet={[
                                { data: abdominalPlotData?.week?.maxAbdominalGrith, name: "Max Abdominal G", color: "red", },
                                { data: abdominalPlotData?.week?.minAbdominalGrith, name: "Min Abdominal G", color: "green", }
                            ]} 
                            yTitle={"Abdominal griths (cm)"} 
                            date={abdominalPlotDate}
                            setDate={setAbdominalPlotDate}
                            view={abdominalWeightView}
                            setView={setAbdominalWeightView}
                            />
                        </Col>
                        }
                    </Row>
                    <EditAbdominalWeight
                        open={openEditAbdominalWeight}
                        setOpen={setOpenEditAbdominalWeight} 
                        data={selectedAbdominalWeight}
                        patientId={patientId}
                        onSave={async (abdominalWeight) => {
                            return await saveAbdominalWeight(abdominalWeight)
                        }}
                        view={sweatTestView}
                        setView={setSweatTestView}
                    />
                    </>
                )}
                
                <br />
                {OpenInformation("SWEAT RATE TESTS", "Open sweat tests", sweatTestInfo, setSweatTestInfo)}
                {sweatTestInfo && (
                    <>
                    <Row>
                        <Col>
                            <TableHeader 
                                title="Sweat rate tests"
                                onClick={() => {
                                    setOpenEditSweatTest(true);
                                    setSelectedSweatTest(null);
                                }}
                                numberCol={2} 
                            />
                            <FiltersCard 
                                filtersContent={renderFiltersContent}
                                collapsed={collapsed}
                                setCollapsed={setCollapsed}
                                handleClearFilters={handleClearFilters}
                                handleSearch={handleSearch}
                            />
                            {!refresh && sweatTests?.length > 0 && <Table data={getSweatTestsData(filteredData)} columns={sweatTestsColumns}/>}
                        </Col>
                        {sweatTests?.length > 0 && 
                        <Col>
                            {/* <MeasurePlot 
                                monthSet={[{data: sweatPlotData?.month, name: "", color: "violet"}]}
                                weekSet={[{data: sweatPlotData?.week, name: "", color: "violet"}]} 
                                yTitle={"Sweat rate"} 
                                date={sweatPlotDate}
                                setDate={setSweatPlotDate}
                                view={sweatTestView}
                                setView={setSweatTestView}
                            /> */}
                            <SweatPlot />
                        </Col>
                        }
                    </Row>
                    <EditSweatTest 
                    patientId={patientId}
                    data={selectedSweatTest}
                    open={openEditSweatTest}
                    setOpen={setOpenEditSweatTest}
                    onSave={async (sweatTest) => {
                        return await saveSweatTest(sweatTest)
                    }}
                    />
                    </>
                )}

            </CardBody>
        </Card>
    )
}