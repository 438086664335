import React, { useEffect, useState } from "react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import enLocale from '@fullcalendar/core/locales/en-gb';
import { snackColor, mainColor, activityColor, intakeFormat, getType, calcUnitsNt, blockIsMain, calcRequeriments, calcExerciseMinutes } from "../../../utils/structuresCalcs";
import { formatFloat, removeHtmlTags } from "../../../utils/helpers";
import MyAutocomplete from "../MyAutocomplete";
import { Col, Row, Button } from "react-bootstrap";
import { ExpandLess, ExpandMore, SpeakerNotes, SpeakerNotesOff, 
  Person, Fastfood, FormatListNumberedRtl, Notifications } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";

function customizeHeaderWeek() {
  const headers = document.querySelectorAll('.fc .fc-col-header-cell');
  
  headers.forEach((header) => {
    header.style.backgroundColor = 'black';
    header.style.textAlign = 'center';
    header.style.padding = '0';
    header.style.margin = '0';
    header.style.justifyContent = 'center';
    header.style.alignItems = 'center';
  });
}

export default function FullCalendarView({
  setDate, setWeek, userDiets, refr, patient, date, 
  initialDate, setInitialDate, setCalendarRef, 
  selectedNtOption, setSelectedNtOption, weekDiet,
  info, setInfo, setTabNumber
}) {

  const [events, setEvents] = useState(0);
  const [totalKcalsByDate, setTotalKcalsByDate] = useState([]);
  const [first, setFirst] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    customizeHeaderWeek();
    setRefresh(false);
  }, [refresh])

  useEffect(() => {
    setRefresh(true);
  }, [selectedNtOption]);

  useEffect(() => {
    const fetchData = async () => {
      const { events } = await getInfoDiets(weekDiet);
      setEvents(events);
    };

    if (weekDiet) fetchData();
  }, [weekDiet, refr]);

	const ntOptions = [
		{ _id: 3, value: "g"},
		{ _id: 1, value: "g/kg"},
		{ _id: 2, value: "%"},
	];

  const getFormattedDate = (date) => {
    const localDate = new Date(date);
    
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(localDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  async function getInfoDiets(weekDiet) {
    let data = [];

    for (let i = 0; i < weekDiet?.length; ++i) {
      const actD = weekDiet[i];
      const startDate = getFormattedDate(actD?.date);
      if (actD?.diet?.blocks?.length > 0) 
        data.push({
          start: startDate,
          title: '',
          extendedProps: {
            type: "Notifications",
            structure: actD?.diet || [],
            notes: {
              privateNotes: actD?.privateNotes,
              publicNotes: actD?.publicNotes,
              patientNotes: actD?.patientNotes,
            },
            weight: actD?.weight,
            number: i
          }
        })
        
        data.push({
          start: startDate,
          title: '',
          extendedProps: {
            type: "Summary",
            structure: actD?.diet || [],
            number: i
          }
        })
      
      // if (actD?.patientNotes) 
      //   data.push({
      //     start: startDate,
      //     title: '',
      //     extendedProps: {
      //       type: "PatientNotes",
      //       patientNotes: actD?.patientNotes,
      //     }
      //   })

      for (let block of actD?.diet?.blocks) {
        const type = (block?.isIntake? (!block?.intake.isSnack || block?.intake?.isSnack === '0')? "Main" : "Snack" : "Exercise");
        const nutritionalValues = {kcals: block.kcals, 
          carbohydrates: block.carbohydrates, fiber: block.fiber, 
          fats: block.fats, proteins: block.proteins};

        let feedback = false;
        if (type === "Exercise") {
          const act = block?.exercise?.activities;
          if (act?.perceivedEffort || act?.gastroentinalDistress) feedback = true;
        }
            
        let elem = {
          start: startDate,
          title: '',
          extendedProps: {
            type: type,
            details: block?.isIntake 
              ? formatIntakeDetails(block, nutritionalValues, actD?.diet?.blocks) 
              : formatExerciseDetails(block),
            patientFeedback: feedback,
            numBlock: block?.number
          }
        };
        data.push(elem)
      }
    }

    return { events: data };
  }

  function formatNameIntake(block, blocks) {
    const name = block?.name;
    const isSnack = (block?.intake?.isSnack);
    if (!isSnack) return name;
    const blockIndex = blocks?.findIndex((x) => x.number === block.number);
    let sumDown = 0, sumUp = 0;
    for (let i = blockIndex-1; i >= 0; i--) { 
      const actB = blocks[i];	
      if (actB?.name === name && actB?.intake?.isSnack) sumDown++; 
      else break;
    }
    for (let i = blockIndex+1; i < blocks.length; i++) { 
      const actB = blocks[i];	
      if (actB?.name === name && actB?.intake?.isSnack) sumUp++; 
      else break;
    }
    if ((sumDown + sumUp) > 0) {
      if (sumUp === 0 && sumDown%3 === 0) return name;
      return `${block.name} (Option ${(sumDown%3)+1})`;
    }
    return name;
  }

  const styleValues='border: none; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'
  
  function formatIntakeDetails(block, nutritionalValues, blocks) {
    const intake = block?.intake;
    let details = '';
    const name = formatNameIntake(block, blocks);
    const kcal = formatFloat(nutritionalValues.kcals);
    const cho = calcUnitsNt(nutritionalValues.carbohydrates, 
      selectedNtOption.value, "carbohydrates", kcal, patient?.selectedWeight || 1);
    const pro = calcUnitsNt(nutritionalValues.proteins, 
      selectedNtOption.value, "proteins", kcal, patient?.selectedWeight || 1);
    const fat = calcUnitsNt(nutritionalValues.fats, 
      selectedNtOption.value, "fats", kcal, patient?.selectedWeight || 1);
    const description = intakeFormat(block, getType(block));
    details += `<div style="white-space: normal; word-wrap: break-word;">
          <strong>${name}</strong>
          <br>${description}</>
          <table style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr>
              <th style="${styleValues}">KCAL</th>
              <th style="${styleValues}">CHO</th>
              <th style="${styleValues}">PRO</th>
              <th style="${styleValues}">FAT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="text-align: center; border: none;">${kcal}</td>
              <td style="text-align: center; border: none;">${cho}</td>
              <td style="text-align: center; border: none;">${pro}</td>
              <td style="text-align: center; border: none;">${fat}</td>
            </tr>
          </tbody>
        </table></div>`;
  
    return details;
  }
  
  function formatExerciseDetails(block) {
    const exercise = block?.exercise?.activities;
    let details = ``;
    details += `<div style="white-space: normal; word-wrap: break-word;">
          <strong>${block?.name}</strong>
          <br>${removeHtmlTags(block?.description) || "There is no description"}</>
          <table style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr>
              <th style="${styleValues}">gCHO/h</th>
              <th style="${styleValues}">mL/h</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="text-align: center; border: none;">${exercise?.totalGChoH || 0}</td>
              <td style="text-align: center; border: none;">${exercise?.totalMlh || 0}</td>
            </tr>
          </tbody>
        </table></div>`;

    return details;
  }

  function OpenInformation({title, arrowTitle, structure, number}) {
    const arrowStyle = {color: '#000'}
    const backCol = 'rgba(200, 200, 200, 255)';
    const val = selectedNtOption.value;
    const w = patient?.selectedWeight || 1;
    const req = structure?.requeriments;
    const kcals = structure?.kcal;

    // function reqFormat(reqV, type) {
    //   if (!reqV) return "";
    //   const min = parseFloat(calcUnitsNt(reqV.min, val, type, req, w))
    //   const max = parseFloat(calcUnitsNt(reqV.max, val, type, req, w))
    //   const fixed = val !== "g/kg" ? 0 : 1;
    //   return `${min.toFixed(fixed)} - ${max.toFixed(fixed)}`;
    // }

    function text(text, value) {
      return (
        <div style={{ display: 'flex', justifyContent: 'left', paddingLeft: 10 }}>
          <h8 style={{color: 'black'}}>{text} <strong>{value}</strong></h8>
        </div>
      )
    }

    function changeInfo(info) {
      let newInfo = [...info];
      newInfo[number] = !newInfo[number];
      setInfo([...newInfo]);
    }

    // const reqCho = reqFormat(structure?.reqCho, "carbohydrates");
    // const reqPro = reqFormat(structure?.reqPro, "proteins");
    // const reqFat = reqFormat(structure?.reqFat, "fats");
    const cho = calcUnitsNt(structure?.carbohydrates, val, "carbohydrates", kcals, w);
    const pro = calcUnitsNt(structure?.protein, val, "proteins", kcals, w);
    const fat = calcUnitsNt(structure?.fats, val, "fats", kcals, w);
    return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between', backgroundColor: backCol, width: '100%', padding: '0 0px'}}>
        <Tooltip title={arrowTitle}>
          <Button
              size="small"
              style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
            onClick={() => changeInfo(info)}
          >
          <h7 style={{fontWeight: 'bold', color: 'black'}}>{title.toUpperCase()}</h7>
          </Button>
        </Tooltip>
        <Tooltip title={arrowTitle}>
          <Button
              size="small"
              style={{backgroundColor: 'transparent', borderColor: 'transparent', marginLeft: 10}}
            onClick={() => changeInfo(info)}
          >
              {info[number]? (<ExpandLess key={info} style={arrowStyle}/>) : 
              (<ExpandMore key={info} style={arrowStyle}/>)}
          </Button>
        </Tooltip>
      </div>
      {info[number] && (
        <>
          <div style={{backgroundColor: backCol, width: '100%'}}>
            {text(`Energy req (kcal):`, req || 0)}
            <br />
            {text(`Energy (kcal):`, formatFloat(kcals))}
            <br />
            {text(`CHO (${val}):`, cho)}
            <br />
            {text(`PRO (${val}):`, pro)}
            <br />
            {text(`FAT (${val}):`, fat)}
            <br />
          </div>
        </>
      )}
    </>
    )
  }

  const NotificationsInfo = ({info}) => {
    const { start, extendedProps } = info.event;
    const { patientNotes, privateNotes, publicNotes } = extendedProps.notes;

    function openModal(num) {
      setDate({date: start, dateStr: getFormattedDate(start), numBlock: info.event.extendedProps.numBlock})
      setTabNumber(num);
    }

    const styleButtons = {
        background: "#white", 
        color: "#000", //SE PUEDE PONER EN VERDE rgb(50 200 50 / 80%)
        border: "none", 
        borderRadius: "5px",
        borderColor: "#000",
        cursor: "pointer",
    }

    return (
      <div style={{
          display: "flex", 
          justifyContent: "space-between", 
          alignItems: "center", 
      }}>
          <Row>
            <Col sm={2} md={4}>
            <button
                key={1} 
                style={{...styleButtons, opacity: privateNotes ? 1 : 0.2}}
                onClick={() => {
                  openModal(2)
                }}
            >
                <SpeakerNotes />
            </button>
            </Col>
            <Col sm={2} md={4}>
            <button 
                key={2} 
                style={{...styleButtons, opacity: publicNotes ? 1 : 0.2}}
                onClick={() => {
                  openModal(2)
                }}
            >
                <SpeakerNotesOff />
            </button>
            </Col>
            <Col sm={2} md={4}>
            <button
                key={3} 
                style={{...styleButtons, opacity: patientNotes ? 1 : 0.2}}
                onClick={() => {
                  openModal(2)
                }}
            >
                <Person />
            </button>
            </Col>
            <Col sm={2} md={4}>
            <button 
                key={4} 
                style={{...styleButtons, opacity: extendedProps?.weight ? 1 : 0.2}}
                onClick={() => {
                  openModal(0)
                }}
            >
                <Fastfood />
            </button>
            </Col>
            <Col sm={2} md={4}>
            <button 
                key={5} 
                style={{...styleButtons, opacity: false ? 1 : 0.2}} //AQUI IRAN LOS FORMS
            >
                <FormatListNumberedRtl />
            </button>
            </Col>
            <Col sm={2} md={4}>
            <button 
                key={6} 
                style={{...styleButtons, opacity: false ? 1 : 0.2}} //AQUI LAS NOTIFICACIONES HECHAS
                onClick={() => {
                  openModal(2)
                }}
            >
                <Notifications />
            </button>
            </Col>
          </Row>
          
      </div>
  );
  }

  function dietContent(info) {
    const { type, details, structure, number, patientFeedback } = info.event.extendedProps;
        
    if (type === "Notifications")
    return (
      <>
        <NotificationsInfo info={info}/>
      </>
    )
    else if (type === "Summary")
    return (
      <>
        <OpenInformation 
          title="Summary" arrowTitle="Open extra information" 
          structure={structure} number={number}
        />
      </>
    )
    // else if (type === "PatientNotes")
    // return (
    //   <div style={{
    //     backgroundColor: 'rgb(50 200 50 / 80%)',
    //     border: '1px solid #ccc',
    //     padding: '5px',
    //     borderRadius: '5px',
    //     color: 'black',
    //     textAlign: 'center'
    //   }}>
    //     <h7 style={{color: 'black', fontWeight: 'bold'}}>The patient has</h7>
    //     <br />
    //     <h7 style={{color: 'black', fontWeight: 'bold'}}>returned feedback!</h7>
    //     <br />
    //     <h7 style={{color: 'black'}}>{patientNotes.slice(0, 20)}...</h7>
    //   </div>
    // )
    else 
    return (
      <div style={{
        backgroundColor: type === 'Snack' ? snackColor : 
                          type === 'Main' ? mainColor : 
                          activityColor,
        border: '1px solid #ccc',
        padding: '5px',
        borderRadius: '5px',
        color: 'black',
        position: 'relative'
      }}>
        <div dangerouslySetInnerHTML={{ __html: details }} />

        {patientFeedback && 
          <div style={{
            position: 'absolute',
            top: '0px',
            right: '0px',
            width: '10px',
            height: '10px',
            backgroundColor: 'rgb(50 200 50 / 80%)',
            borderRadius: '50%',
          }}/>
        }
      </div>
    );
  }

  function dayCellContent(arg) {
    const date = new Date(arg.date);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses comienzan desde 0
    const weekday = date.toLocaleDateString('en-US', { weekday: 'short' }); // Cambia 'en-US' por tu idioma si es necesario

    // return `${weekday} ${day}/${month}`;
    const formattedDate = getFormattedDate(arg.date);
    // const totalKcals = totalKcalsByDate[formattedDate] || 0;
    return (
      <>
          {/* <OpenInformation title="Summary" arrowTitle="Open extra information" /> */}
      </>
    );
  }

  const handleDate = (event) => {
    setInitialDate(event.target.value);
    setFirst(true);
		setRefresh(true);
    // setDate({date: new Date(dat), dateStr: getFormattedDate(dat)});
    // setRefresh(true);
  }

  const getMonthDate = (date) => {
    const localDate = new Date(date);
    
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  return (
    <>
    {!refresh && (
      <>
        <Row>
          <Col
          style={{ 
            marginLeft: "150px", 
            marginTop: "-1.5px", 
            position: 'absolute',
            height: '35px'
          }}>
            <input
            style = {{height: '35px'}}
                type="date"
                id="date-selector"
                value={initialDate}
                onChange={handleDate}
            />
          </Col>
        </Row>
        <Row>
          <Col 
              style={{ 
                marginLeft: "310px", 
                marginTop: "-20.5px", 
                position: 'absolute',
                width: '200px',
                height: '35px'
              }}>
            <MyAutocomplete 
              options={ ntOptions }
              getOptionLabel={(option) =>
                option.value
              }
              value={ ntOptions.find((option) => option._id === selectedNtOption?._id) || null }
              onChange={(event, selected) => {
                setCalendarRef(true);
                setSelectedNtOption(selected);
              }}
              placeholder="Select units"
              label={""}
            />
          </Col>
        </Row>
        <FullCalendar
          plugins={[interactionPlugin, dayGridPlugin]}
          initialView="dayGridWeek"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: ""
          }}
          firstDay={1}
          editable={true}
          selectable={true}
          initialDate={initialDate}
          //height={700}
          eventStartEditable={false}
          eventDurationEditable={false}
          events={events}
          eventContent={(info) => dietContent(info)}
          dateClick={(info) => {
            // console.log("DATE", info)
            setDate({date: info.date, dateStr: info.dateStr})
            setTabNumber(0);
          }}
          eventClick={(info) => {
            // console.log(info.event.extendedProps)
            const type = info.event.extendedProps.type;
            if (type !== "Summary" && type !== "Notifications") {
              setDate({date: info.event.start, dateStr: getFormattedDate(info.event.start), numBlock: info.event.extendedProps.numBlock})
              if (type === "PatientNotes") setTabNumber(2);
              else setTabNumber(0);
            }
          }}
          datesSet={(info) => { 
            setWeek(info.startStr)
            setInitialDate(getMonthDate(info.start))
            customizeHeaderWeek();
          }}
          locale={enLocale}
          dayHeaderFormat={{ weekday: 'short', day: '2-digit', month: '2-digit' }}
          // dayCellContent={dayCellContent}
        />
      </>
    )}
  </>
  );
}