import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { changeStatusVisit, deleteVisit, getVisits } from "../../../../api/visit";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
} from "../../../components/tables/table";

function getData(visits) {
	let data = [];
	for (let i = 0; i < visits.length; ++i) {
		const elem = {};
		elem.visitDate = visits[i].visitDate;
		elem.visitType = getNonEmpty(visits[i].visitType?.fullName);
		elem.visitState = visits[i]?.visitState;
		elem.patient = visits[i].user?.name+' '+visits[i].user?.surname;
		elem.createdAt = visits[i].createdAt;
		elem.id = visits[i]._id;
		elem.active = visits[i].active;
		data = data.concat(elem);
	}

	return data;
}

export default function VisitsPage() {
	const [data, setData] = useState([]);
	const [visitId, setVisitId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item._id === cell);
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/edit-visit/" + cell)}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setVisitId(cell);
									setOpenConfirmDialog(1);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "#00F" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setVisitId(cell);
									setOpenConfirmDialog(2);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const columns = [
		{
			dataField: "patient",
			text: "Patient",
			sort: true,
			headerAlign: "left",
			align: "left",
		},
		{
			dataField: "visitDate",
			text: "Date",
			formatter: dateFormatter,
			sort: true,
			headerAlign: "left",
			headerStyle: { width: '175px' },
			align: "left",
		},
		{
			dataField: "visitType",
			text: "Visit Type",
			sort: true,
			headerStyle: { width: '175px' },
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "visitState",
			text: "Visit State",
			sort: true,
			headerStyle: { width: '175px' },
			headerAlign: "center",
			align: "center",
		},
		// {
		// 	dataField: "name",
		// 	text: "Notes",
		// 	sort: true,
		// },
		{
			dataField: "createdAt",
			text: "Created at",
			headerAlign: "center",
			align: "center",
			headerStyle: { width: '175px' },
			formatter: dateFormatter,
			sort: true,
		},
		{ 
			dataField: "id", 
			text: "", 
			headerStyle: { width: '150px' },
			formatter: buttonFormatter 
		},
	];

	useEffect(() => {
		getVisits()
			.then((res) => {
				if (res.status === 200) {
					setData(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get visits.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Visits list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => history.push("/edit-visit")}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={getData(data)} columns={columns} />
					<ConfirmDialog
						title={`Are you sure you want to ${
							visitId?.active ? "disable" : "enable"
						} this visit?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusVisit(visitId.id, !visitId?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												visitId?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `Visit ${
												visitId?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											visitId?.active
												? "disable"
												: "enable"
										} visit.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={"Are you sure you want to remove this visit?"}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteVisit(visitId)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Visit removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: "Could not remove visit.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
