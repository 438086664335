import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MuiThemeProvider, TextField, createMuiTheme, } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import ConfirmDialog from '../../dialogs/ConfirmDialog';
import OnlyTextDate from '../../textFields/OnlyTextDate';
import OnlyNumberRead from '../../textFields/OnlyNumberRead';
import EfficientlessText from '../../textFields/EfficientlessText';
import { alertError } from '../../../../utils/logger';
import { losedWeight, perLosedWeight, sweatRate } from '../../../../utils/measures/mesuresCalcs';
import { formattedValueNumber } from '../../../../utils/numbers';
import MyAutocomplete from '../../MyAutocomplete';

function getEmptyTest(patientId) {
    const today = new Date();
    const date = new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().split('.')[0];
    return {
        userId: patientId,
        date: date,
        averageTemperature: 0,
        realizedActivity: null,
        trainingIntensity: null,
        trainingDuration: 0,
        preTrainingWeight: 0,
        postTrainingWeight: 0,
        intakeWeight: 0,
        urinations: 0,
        depositions: 0,
        observations: null,
        active: true
    }
}

export default function EditSweatTest(props) {
    const { patientId, data, open, setOpen, onSave } = props;

    const [sweatTest, setSweatTest] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
    const [changes, setChanges] = useState(-3);

    useEffect(() => {
        if (!data || !open) setSweatTest(getEmptyTest(patientId));
        else {
            let newSweatTest = {...data}
            newSweatTest.date = getDateFormat(data);
            newSweatTest = refreshCalcs(newSweatTest);
            setSweatTest({...newSweatTest});
        }
    }, [data, open])

    useEffect(() => {
        if (changes <= 0) setChanges(changes+1)
    }, [sweatTest])

    const zValues = ["Z1", "Z2", "Z3", "Z4", "Z5"];

    function getDateFormat(test) {
        const testDate = new Date(test.date);
        const date = new Date(testDate.getTime() - testDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
        return date;
    }

    function refreshCalcs(test) {
        test.losedWeight = losedWeight(test.preTrainingWeight, test.postTrainingWeight, 
            test.intakeWeight, test.urinations, test.depositions);
        test.perLosedWeight = perLosedWeight(test.losedWeight, test.preTrainingWeight);
        test.sweatRate = sweatRate(test.losedWeight, test.trainingDuration);
        return test;
    }

    const handleChange = (element) => (event) =>{
        const value = event.target.value;

        const formattedValue = formattedValueNumber(value, true);
        setSweatTest((prevSweatTest) => {
            let test = {...sweatTest, [element]: formattedValue};  
            test = refreshCalcs(test);
            return test;
        });
    } 

    const handleChangeNormal = (element) => (event) =>{
        const value = event.target.value;
        setSweatTest((prevSweatTest) => {
            let test = {...sweatTest, [element]: value};  
            test = refreshCalcs(test);
            return test;
        });
    } 

    function onClose() {
        setOpen(false);
        setChanges(-3);
    }

    return (
        <Dialog disableBackdropClick={true} 
            fullWidth={true}
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="lg"
            aria-labelledby="table-dialog"
        >
            <DialogTitle id="table-dialog">{"Edit sweat test"}</DialogTitle>
                
            <DialogContent>
                <Row>
                    <Col sm={6}><EfficientlessText title={"Place"} element={"place"} handleChange={handleChangeNormal} data={sweatTest} type={"text"}/></Col>
                    <Col sm={6}><OnlyTextDate title={"Date"} element={"date"} handleChange={handleChangeNormal} data={sweatTest} specific={false}/> </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <TextField
                            id={"realizedActivity"}
                            label={"Training description"}
                            value={sweatTest?.realizedActivity}
                            onChange={handleChangeNormal("realizedActivity")}
                            InputLabelProps={{
                            shrink: true
                            }}
                            margin="normal"
                            variant="standard"
                            type='text'
                            required
                            multiline
                        />
                    </Col>
                    <Col sm={6}>
                        <TextField
                            id={"observations"}
                            label={"Comments"}
                            value={sweatTest?.observations}
                            onChange={handleChangeNormal("observations")}
                            InputLabelProps={{
                            shrink: true
                            }}
                            margin="normal"
                            variant="standard"
                            type='text'
                            required
                            multiline
                        />
                    </Col>
                </Row>
                <Row>
                    <Col><EfficientlessText title={"Average temperature (ºC)"} element={"averageTemperature"} handleChange={handleChange} data={sweatTest} type={"number"}/></Col>
                    <Col>
                        <MyAutocomplete 
							options={  zValues }
							getOptionLabel={(option) => option}
							value={ zValues.find((x) => x === sweatTest?.trainingIntensity) || null }
							onChange={(event, selected) => {
								setSweatTest({
									...sweatTest,
									trainingIntensity: selected
								})
							}}
							placeholder="Select training intensity"
                            label={"Training intensity"}
						/>
                    </Col>
                    {/* <Col><EfficientlessText title={"Training intensity"} element={"trainingIntensity"} handleChange={handleChangeNormal} data={sweatTest} /></Col> */}
                    <Col><EfficientlessText title={"Training duration (min)"} element={"trainingDuration"} handleChange={handleChange} data={sweatTest} type={"number"}/></Col>
                    <Col><EfficientlessText title={"Pre training weight (kg)"} element={"preTrainingWeight"} handleChange={handleChange} data={sweatTest} type={"number"}/></Col>
                    <Col><EfficientlessText title={"Post training weight (kg)"} element={"postTrainingWeight"} handleChange={handleChange} data={sweatTest} type={"number"}/></Col>
                </Row>
                <Row>
                    <Col><EfficientlessText title={"Intake weight (g)"} element={"intakeWeight"} handleChange={handleChange} data={sweatTest} type={"number"}/></Col>
                    <Col><EfficientlessText title={"Urinations"} element={"urinations"} handleChange={handleChange} data={sweatTest} type={"number"}/></Col>
                    <Col><EfficientlessText title={"Depositions"} element={"depositions"} handleChange={handleChange} data={sweatTest} type={"number"}/></Col>
                </Row>
                <br />
                <h5>Test calcs</h5>
                <Row>
                    <Col sm={4}><OnlyNumberRead title={"Lost weight"} element={"losedWeight"} handleChange={handleChange} data={sweatTest} /></Col>
                    <Col sm={4}><OnlyNumberRead title={"%Lost weight"} element={"perLosedWeight"} handleChange={handleChange} data={sweatTest} /></Col>
                    <Col sm={4}><OnlyNumberRead title={"Sweat rate (L/min)"} element={"sweatRate"} handleChange={handleChange} data={sweatTest} /></Col>
                </Row>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        if (changes > 0) setOpenConfirmDialog(1);
                        else onClose();
                    }}
                    variant="outlined"
                    style={{ marginRight: "20px" }}
                >
                    Back
                </Button>
                <Button
                    onClick={async () => {
                        function alert(required) {
                            alertError({
                                error: null,
                                customMessage: `${required} path is required` 
                            })
                        }

                        if (!sweatTest?.place) {alert("Place"); return;}
                        if (!sweatTest?.realizedActivity) {alert("Realized activity"); return;}
                        if (!sweatTest?.trainingIntensity) {alert("Training intensity"); return;}

                        const res = await onSave(sweatTest)
                        if (res) onClose();
                    }}
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "20px" }}
                >
                    Save sweat test
                </Button>
                <ConfirmDialog
                    title={
                        "Are you sure you want to go back? You will lose all your changes"
                    }
                    open={openConfirmDialog === 1}
                    setOpen={setOpenConfirmDialog}
                    onConfirm={() => {
                        onClose();
                    }}
                />
            </DialogActions>
            
        </Dialog>
    )
}