import React, { useEffect, useState } from 'react';
import Table, { buttonsStyle } from "../app/components/tables/table";
import { foodCalcs, getDataBlocks } from './structuresCalcs';
import { getFoodById } from '../api/food';
import { alertError } from './logger';
import EditPatientTramIntakeDialog from '../app/components/dialogs/userCompetitions/EditPatientTramIntakeDialog';
import { Card, CardBody } from '../_metronic/_partials/controls';
import { Col, Row } from 'react-bootstrap';
import OnlyText from '../app/components/textFields/OnlyText';
import ConfirmDialog from '../app/components/dialogs/ConfirmDialog';
import { deleteSpecificsTramsXUser, getTramsCompetitionUser } from '../api/tramXuser';
import { useSkeleton } from '../app/hooks/useSkeleton';
import { Edit } from '@material-ui/icons';
import { Button, TextField, Tooltip } from '@material-ui/core';
import MyAutocomplete from '../app/components/MyAutocomplete';
import { getCompetitionById } from '../api/competition';
import { getSportFoods } from '../api/sportFood';

export function calcCompetitionData(competition) {
    let positiveUnevenness = 0;
    let negativeUnevenness = 0;
    let totalKilometers = 0;

    const trams = competition?.trams || [];
    for (let i = 0; i < trams.length; ++i) {
        const actT = trams[i];
        positiveUnevenness += parseFloat(actT?.positiveUnevenness) || 0;
        negativeUnevenness += parseFloat(actT?.negativeUnevenness) || 0;
        totalKilometers += parseFloat(actT?.longitude) || 0;
    }

    return {
        ...competition,
        positiveUnevenness: positiveUnevenness,
        negativeUnevenness: negativeUnevenness,
        totalKilometers: totalKilometers
    }
}

function sortByNumber(a, b) {
    if (a.number < b.number) return -1;
    if (a.number > b.number) return 1;
    return 0;
}

export function calcsFoods(foods, time) {
    let choG = 0;
    let sodiumMg = 0;
    let sodiumMgL = 0;
    let waterMl = 0;
    let caffeine = 0;
    
    for (let i = 0; i < foods.length; ++i) {
        const actF = foods[i];
        const specF = actF.food;
        waterMl += specF?.water || 0;
        choG += specF?.carbohydrates;
        sodiumMg += specF?.sodium;
        caffeine += specF?.caffeine;
    }

    let choGH = 0, waterMlH = 0;
    if (time) {
        const hours = time / 60;
        choGH = (choG / hours); 
        waterMlH = (waterMl / hours);
    }
    
    return { 
        waterMl: waterMl, waterMlH:  waterMlH,
        choG: choG, choGH: choGH, 
        sodiumMg: sodiumMg, sodiumMgL: sodiumMgL,
        caffeine: caffeine
    }
}

export const InfoPatientCompetition = (props) => {
    const { 
        userId, openEditTramUserDialog, setOpenEditTramUserDialog,
        competition, setCompetition, competitions,
        tramsXuser, setTramsXuser, date
     } = props;

    const [refresh, setRefresh] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
	const [selectedCompetition, setSelectedCompetition] = useState(null);
    const [competitionOptions, setCompetitionOptions] = useState([]);
    const [completeCompetition, setCompleteCompetition] = useState(null);
    const [selectedTram, setSelectedTram] = useState(null);
    const [foods, setFoods] = useState([]);
    const [foodOptions, setFoodOptions] = useState([]);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

    function getEmptyTram() {
        return {
            _id: undefined,
            numTram: '',
            name: '',
            idClient: userId,
            idCompetition: competition?.competitionId,
            foods: [],
            extrInfo: ''
        };
    }

    useEffect(() => {setRefresh(false);}, [refresh]);

    useEffect(() => {
        if (competitions?.length !== 0) actualizeCompetitionOptions(competitions)
    }, [competitions])

    useEffect(() => {
        if (competition?.competitionId) {
            getCompetitionById(competition.competitionId)
                .then((res) => {
                    setCompleteCompetition(res.data);
                })
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: "Could not get all the information about the competition"
                    })
                })
        }

    	if (foods?.length === 0) {
			getSportFoods()
				.then((res) => {
					if (res.status === 200) {
						setFoods(res.data);
						actualizeFoodOptions(res.data);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get foods.",
					});
				});
		}
	
    }, [])

	function actualizeFoodOptions(foods) {
		let data = [];

		for (let i = 0; i < foods?.length; ++i) {
			let elem = {};
			const actF = foods[i];

			elem._id = actF._id;
			elem.name = actF.fullName.es;

			data.push(elem)
		}

		setFoodOptions(data);
	}

    const statusOptions = ["En espera dels temps de pas", "Preparada per planificar", 
        "En procés de planificació", "Planificada", "Realitzada"];

    function getTramsTableData(tramsXuser) {
        function sortByNumber(a, b) {
            if (a.number > b.number) return 1;
            if (a.number < b.number) return -1;
            return 0;
        }

        let data = [];

        const comp = competitions.find((x) => x._id === competition.competitionId)
        const trams = comp?.trams;
        for (let i = 0; i < trams?.length; ++i) {
            let elem = {};
            const act = trams[i];
            const definedTram = tramsXuser?.find((x) => parseInt(x.numTram) === act.number)
            
            elem.name = act.name;
            elem.number = act.number;
			elem.time = definedTram?.time;
            elem.longitude = act?.longitude;
            elem.positiveUnevenness = act?.positiveUnevenness;
            elem.negativeUnevenness = act?.negativeUnevenness;
            let completeFoods = [];
            for (let i = 0; i < definedTram?.foods?.length; ++i) {
                const actF = definedTram?.foods[i];
                const food = foods.find((x) => x._id === actF.food);
                if (food)
                    completeFoods.push({
                        ...actF,
                        food: food
                    })
            }
            const calcs = calcsFoods(completeFoods, 1);
            elem.waterMl = calcs.waterMl;
            elem.waterMlH = calcs.waterMlH;
            elem.choG = calcs.choG;
            elem.choGH = calcs.choGH;
            elem.sodiMg = calcs.sodiumMg;
            elem.sodiMgL = calcs.sodiumMgL;
            elem.caffeine = calcs.caffeine;

            data.push(elem);
        }

        data.sort(sortByNumber)

        return data;
    }

    const tramsTable = [
        {
            dataField: "name",
            text: "title"
        },
		{ 
			dataField: "longitude", 
			text: "Distance (km)",
			formatter: fixedZero,
			headerAlign: "center",
			align: "center",
		},
		{ 
			dataField: "positiveUnevenness", 
			text: "Elevation gain (m)",
			formatter: fixedZero,
			headerAlign: "center",
			align: "center",
		},
        { 
			dataField: "negativeUnevenness", 
			text: "Elevation loss (m)",
			formatter: fixedZero,
			headerAlign: "center",
			align: "center",
		},
        { 
			dataField: "time", 
			text: "time",
			// formatter: unevennessFormater,
			headerAlign: "center",
			align: "center",
		},
        {
            dataField: "waterMl",
            text: "water (ml)",
            align: 'center',
            headerAlign: "center",
            formatter: fixedZero
        },
        {
            dataField: "waterMlH",
            text: "water (ml/h)",
            align: 'center',
            headerAlign: "center",
            formatter: fixedZero
        },
        {
            dataField: "choG",
            text: "CHO (g)",
            align: 'center',
            headerAlign: "center",
            formatter: fixedZero
        },
        {
            dataField: "choGH",
            text: "CHO (gcho/h)",
            align: 'center',
            headerAlign: "center",
            formatter: fixedZero
        },
        {
            dataField: "sodiMg",
            text: "sodi (mg)",
            align: 'center',
            headerAlign: "center",
            formatter: fixedZero
        },
        {
            dataField: "sodiMgL",
            text: "sodi (mg/l)",
            align: 'center',
            headerAlign: "center",
            formatter: fixedZero
        },
        {
            dataField: "caffeine",
            text: "caffeine",
            align: 'center',
            headerAlign: "center",
            formatter: fixedZero
        },
        {
            dataField: "number",
            text: "",
            formatter: tramsButtonFormatter,
            align: "right",
        }
    ]

    function fixedZero(cell) {return parseFloat(cell).toFixed(0);}
    function fixedOne(cell) {return parseFloat(cell).toFixed(1);}

    function quantityFormatter(cell) {
        let text = "";

        if (cell?.length > 0) {
            text = cell[0].grams;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.grams;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    function foodsFormatter(cell) {
        let text = "no intake";

        if (cell?.length > 0) {
            text = cell[0].name;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.name;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    function observationsFormatter(cell) {
        let text = "";

        if (cell?.length > 0) {
            text = cell[0].observations;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.observations;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

	function tramsButtonFormatter(cell, row) {
        let tram = tramsXuser?.find((x => parseFloat(x.numTram) === parseFloat(cell)));
		if (!tram) tram = {...getEmptyTram(), name: row.name, numTram: row.number};

        return (
			<>
                <Tooltip title="Edit tram info">
                    <Button
                        style={buttonsStyle}
                        size="small"
                        onClick={() => {
                            setOpenEditTramUserDialog(true);
                            setSelectedTram(tram);
                        }}
                    >
                        <Edit />
                    </Button>
                </Tooltip>
            </>
		)
	}
    
    function actualizeCompetitionOptions(competitions) {
        let data = [];

		data.push({
			competitionId: -1,
			competitionName: "None"
		})

        for (let i = 0; i < competitions?.length; ++i) {
            let elem = {};
            const act = competitions[i];

            elem.competitionId = act._id;
            elem.competitionName = act.fullName.es;

            data.push(elem);
        }

        setCompetitionOptions(data);
    }

    function actualizeTramsData(competition) {
        if (userId && (date?.dateStr ? date?.dateStr : true) && competition?.competitionId && competition?.competitionId !== -1) 
			getTramsCompetitionUser(competition?.competitionId, userId, date.dateStr)
				.then((res) => {
					if (res.status === 200) {
						setTramsXuser(res.data);
						disableLoadingData();
					}
				})
				.catch((error) => {
					console.log("No hay tramos")
				});
    }

    function actualizeCompetition(selected) {
		if (competition?.competitionId && competition?.competitionId !== -1) { 
			deleteSpecificsTramsXUser(competition?.competitionId, userId, competition.date)
				.then((res) => {
					if (res.status === 204) {

					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "There was a problem actualizing the trams information"
					})
				})
		}
		if (userId) actualizeTramsData(selected);
		const newCompetition = {
			...competition,
			competitionId: selected.competitionId,
			competitionName: selected.competitionName
		}
		setCompetition(newCompetition);
		actualizeTramsData(newCompetition);
		// setChanges(changes+1);
		setRefresh(true)
	}

    const UserFeedback = () => {
        const fb = competition?.userFeedback;
        return (
            <>
                <h4>Feedback</h4>
                <Row>
                    <Col>
                        <TextField
                            id={`feedbackTime`}
                            label="Total time"
                            value={`${fb?.totalTime ? fb.totalTime : "Not defined by the user..."}`}
                            inputProps={{ readOnly: true }}
                            required
                            margin="normal"
                            variant="standard"
                            type="text"
                            className="readonly"
                        />
                    </Col>
                    <Col>
                        <TextField
                            id={`gastroentinals`}
                            label="Gastroentinals"
                            value={`${fb?.gastroentinals ? fb.gastroentinals : "Not defined by the user..."}`}
                            inputProps={{ readOnly: true }}
                            required
                            margin="normal"
                            variant="standard"
                            type="text"
                            className="readonly"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            id={`notes`}
                            label="Notes"
                            value={`${fb?.notes ? fb.notes : "Not defined by the user..."}`}
                            inputProps={{ readOnly: true }}
                            required
                            margin="normal"
                            variant="standard"
                            type="text"
                            className="readonly"
                            multiline
                        />
                    </Col>
                </Row>
            </>
        )
    }

    function handleChange(element, value) {
        setCompetition({...competition, [element]: value})
        setRefresh(true);
    }

    return (<>
        <ConfirmDialog
            title={
            "Are you sure you want to change the competition? You will lose all your changes"
            }
            open={openConfirmDialog === 2}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
                actualizeCompetition(selectedCompetition)
                setOpenConfirmDialog(-1);
            }}
        />
        <Card>
            <CardBody>
                <Row>
                    <Col>
                        <MyAutocomplete
                            options={ competitionOptions }
                            getOptionLabel={(option) =>
                                option.competitionName
                            }
                            value={ competitionOptions.find(option => option.competitionId === competition?.competitionId) || null }
                            onChange={(event, selected) => {
                                if (!competition?.competitionId || competition?.competitionId === -1) actualizeCompetition(selected);
                                else {
                                    setOpenConfirmDialog(2)
                                    setSelectedCompetition(selected);
                                }
                            }}
                            placeholder="Select competition"
                            label={"Type of competition"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <MyAutocomplete 
                            options={ statusOptions }
                            getOptionLabel={(option) =>
                                option
                            }
                            value={ statusOptions.find(option => option === competition?.status) || null }
                            onChange={(event, selected) => {
                                setCompetition({
                                    ...competition,
                                    status: selected
                                })
                            }}
                            placeholder="Select status competition"
                            label={"Status competition"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <OnlyText title="Total time" element="totalTime" data={competition} specific={false}
                            handleChange={() => (event) => {
                                handleChange("totalTime", event.target.value);
                            }}	
                        />
                    </Col>
                    <Col sm={4}>
                        <OnlyText title="Medium pace" element="mediumPace" data={competition} specific={false}
                            handleChange={() => (event) => {
                                handleChange("mediumPace", event.target.value);
                            }}	
                        />
                    </Col>
                    <Col sm={4}>
                        <OnlyText title="Arrival time" element="arrivalTime" data={competition} specific={false}
                            handleChange={() => (event) => {
                                handleChange("arrivalTime", event.target.value);
                            }}	
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col><h4>Trams</h4></Col><Col/><Col/>
                    <Col />
                </Row>
                <br/>
                {!refresh && (
                    <Table 
                        columns={tramsTable}
                        data={getTramsTableData(tramsXuser)}
                    />
                )}
                <br /><br />
                <UserFeedback />
            </CardBody>
        </Card>

        <EditPatientTramIntakeDialog 
            open={openEditTramUserDialog}
            setOpen={setOpenEditTramUserDialog}
            data={selectedTram}
            userId={userId}
            competitionId={competition?.competitionId}
            competition={completeCompetition}
            foods={foods}
            setFoods={setFoods}
            foodOptions={foodOptions}
            setFoodOptions={setFoodOptions}

            onSave={(intakeTram) => {
                let newTrams = [...tramsXuser];

                const index = tramsXuser?.findIndex((x) => x.numTram === intakeTram.numTram);
                if (index === -1) newTrams.push(intakeTram);
                else newTrams[index] = intakeTram;
                
                setTramsXuser(newTrams);
                setRefresh(true);
            }}
        />
        
    </>)
}

export const tramsTable = [
    {
        dataField: "name",
        text: ""
    },
    {
        dataField: "longitude",
        text: "KM",
        headerAlign: "center",
        align: "center"
    },
    {
        dataField: "positiveUnevenness",
        text: "D+",
        headerAlign: "center",
        align: "center"
    },
    {
        dataField: "negativeUnevenness",
        text: "D-",
        headerAlign: "center",
        align: "center"
    },
    {
        dataField: "time",
        text: "Tiempo",
        headerAlign: "center",
        align: "center"
    },
    {}
]

export function getDataTrams(trams) {

    let data = [];

    for (let i = 0; i < trams?.length; ++i) {
        let elem = {};
        const actT = trams[i];

        elem.number = actT?.number;
        elem.name = actT?.name;
        elem.longitude = actT?.longitude;
        elem.positiveUnevenness = actT?.positiveUnevenness;
        elem.negativeUnevenness = actT?.negativeUnevenness;
        elem.time = actT?.time || "0:00";

        data.push(elem);
    }
    
    data.sort(sortByNumber)
    return data;
}

export const BlocksTable = React.memo((props) => {
    const { blocks, setNt, patient } = props;

    const [dataBlocks, setDataBlocks] = useState([]);

    useEffect(() => {
        if (blocks) getDataB(blocks);
    }, [blocks])

    const blockColumns = [
        {
            dataField: "number",
            text: "type",
            formatter: typeFormatter,
        },
        {
            dataField: "kcals",
            text: "kcals",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "carbohydrates",
            text: "carbohydrates",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "proteins",
            text: "proteins",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "fiber",
            text: "fiber",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "fats",
            text: "fats",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        }
    ];
    
    function numbersFormatter(cell) {return cell.toFixed(1);}
    
    function typeFormatter(cell) {
        const block = blocks.find((x) => cell === x.number);
        return (block?.isIntake? block?.intake.isSnack? "Snack" : "Main" : "Exercise");
    }

    async function getDataB(blocks) {
        const dataBlocks = await getDataBlocks(blocks, patient);
		setDataBlocks(dataBlocks.data);
        delete dataBlocks.data;
        setNt(dataBlocks);
    }

    return (<Table data={dataBlocks} columns={blockColumns} hideSearchTable={true} hidePagination={true}/>)
}, (prevProps, nextProps) => {
    return prevProps === nextProps;
})

//Punto 4
export const IntakesCompetitionTable = React.memo((props) => {
    const { tramsXuser } = props;

    const [dataIntakes, setDataIntakes] = useState([]);

    useEffect(() => {
        if (tramsXuser) getDataI(tramsXuser);
    }, [tramsXuser])

    const foodColumns = [
        {
            dataField: "name",
            text: ""
        },
        {
            dataField: "grams",
            align: 'center',
            headerAlign: 'center',
            text: "grams"
        },
        {
            dataField: "kcals",
            align: 'center',
            headerAlign: 'center',
            text: "kcals"
        },
        {
            dataField: "carbohydrates",
            text: "carbohydrates",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "proteins",
            text: "proteins",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "fiber",
            text: "fiber",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "fats",
            text: "fats",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        }
    ];
    
    function numbersFormatter(cell) {return cell.toFixed(1);}

    async function getDataI(tramsXuser) {
        const foodIds = [...new Set(tramsXuser.flatMap(item => item.foods.map(food => food.food)))];
        
        let data = [];

        for (let i = 0; i < foodIds?.length; ++i) {
            let elem = {};
            const id = foodIds[i];

            const res = await getFoodById(id)
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: "Could not obtain food"
                    })
                })

            const food = res?.data;
            if (food) {
                elem.name = food.fullName.es;
                elem.kcals = parseFloat(food?.kcals);
                elem.carbohydrates = parseFloat(food?.carbohydrates);
                elem.proteins = parseFloat(food?.proteins);
                elem.fats = parseFloat(food?.fats);
                elem.fiber = parseFloat(food?.fiber);
                elem.grams = 100;
            }

            data.push(elem);
        }

		setDataIntakes(data);
    }
    // return (<></>)
    return (<Table data={dataIntakes} columns={foodColumns} hideSearchTable={true} hidePagination={true}/>)
}, (prevProps, nextProps) => {
    return prevProps === nextProps;
})

//Punto 6
export const NutritionalBag = React.memo((props) => {
    const { trams, tramsXuser } = props;

    const [dataIntakes, setDataIntakes] = useState([]);

    useEffect(() => {
        if (tramsXuser) getDataI(tramsXuser);
    }, [tramsXuser])

    const tramsColumns = [
        {
            dataField: "quantity",
            text: "quantity",
        },
        {
            dataField: "name",
            text: "name",
            align: 'center',
            headerAlign: "center"
        },
        {
            dataField: "type",
            text: "product",
            align: 'center',
            headerAlign: "center",
            formatter: typeFormatter
        },
    ]
    
    function typeFormatter(cell, row) {
        return cell;
    }

    async function getDataI(tramsXuser) {

        async function getFood(food) {
            let elem = {};
            await getFoodById(food.food)
                .then((res) => {
                    elem = res.data;
                })
                .catch((error) => {
                    console.log(error)
                })
            return elem;
        }

        const uniqueFoodsMap = new Map();

        for (const item of tramsXuser) {
            for (const food of item.foods) {
                if (!uniqueFoodsMap.has(food.food)) {
                    const definedFood = await getFood(food);
                    uniqueFoodsMap.set(food.food, { ...food, definedFood });
                } else {
                    const existingFood = uniqueFoodsMap.get(food.food);
                    existingFood.quantity += food.quantity || 0;
                    existingFood.grams += food.grams || 0;
        
                    uniqueFoodsMap.set(food.food, existingFood);
                }
            } 
        }

        let data = [];

        uniqueFoodsMap.forEach((food) => {
            let elem = {};
            
            elem._id = food.food;
            if (food?.selectedPortion >= 0) {
                const defF = food.definedFood;
                const portion = defF?.portions?.find((x) => x._id === food.selectedPortion);
                elem.quantity = `${food.quantity} ${portion.name}`;
            } else elem.quantity = `${food.grams} grams`;
            elem.name = food.name;
            elem.type = food.name;

            data.push(elem);
        })

		setDataIntakes(data);
    }
    // return (<></>)
    return (<Table data={dataIntakes} columns={tramsColumns} hideSearchTable={true} hidePagination={true}/>)
}, (prevProps, nextProps) => {
    return prevProps === nextProps;
})

//Punto 7
export const DistributionIntakesTable = React.memo((props) => {
    const { trams, tramsXuser } = props;

    const [dataIntakes, setDataIntakes] = useState([]);

    useEffect(() => {
        if (tramsXuser) getDataI(tramsXuser);
    }, [tramsXuser])

    const tramsColumns = [
        {
            dataField: "name",
            text: "title"
        },
        {
            dataField: "foods",
            text: "quantity",
            align: 'center',
            headerAlign: "center",
            formatter: quantityFormatter
        },
        {
            dataField: "foods",
            text: "food",
            align: 'center',
            headerAlign: "center",
            formatter: foodsFormatter
        },
        {
            dataField: "foods",
            text: "observations",
            align: 'center',
            headerAlign: "center",
            formatter: observationsFormatter
        },
    ]

    function quantityFormatter(cell, row) {

        function getFoodInfoText(food, definedFoods) {
            if (food?.selectedPortion >= 0) {
                const actDF = definedFoods?.find((x) => x._id === cell[0].food)
                const quantityName = actDF?.portions?.find((x) => x._id === cell[0].selectedPortion)
                return cell[0].quantity + " " + quantityName.name;
            } else return cell[0].grams + " grams";
        }
        let text = "";
        const definedFoods = row?.definedFoods;
        //console.log(cell, row, definedFoods)

        if (cell?.length > 0) {
            text += getFoodInfoText(cell[0], definedFoods);
            for (let i = 1; i < cell?.length; ++i) text += "<br>" + getFoodInfoText(cell[i], definedFoods);
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    function foodsFormatter(cell) {
        let text = "no intake";

        if (cell?.length > 0) {
            text = cell[0].name;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.name;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    function observationsFormatter(cell) {
        let text = "";

        if (cell?.length > 0) {
            text = cell[0].observations;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.observations;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    async function getFoods(foods) {
        let data = [];
        for (let i = 0; i < foods?.length; ++i) {
            await getFoodById(foods[i].food)
                .then((res) => {
                    data.push(res.data);
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        return data;
    }

    async function getDataI(tramsXuser) {
        let data = [];

        for (let i = 0; i < trams?.length; ++i) {
            let elem = {};
            const act = trams[i];
            const definedTram = tramsXuser?.find((x) => parseInt(x.numTram) === act.number)
            const definedFoods = await getFoods(definedTram?.foods);
            
            elem.name = act.name;
            elem.number = act.number;
            elem.foods = definedTram?.foods || [];
            elem.definedFoods = definedFoods || [];

            data.push(elem);
        }

        data.sort(sortByNumber)

		setDataIntakes(data);
    }
    // return (<></>)
    return (<Table data={dataIntakes} columns={tramsColumns} hideSearchTable={true} hidePagination={true}/>)
}, (prevProps, nextProps) => {
    return prevProps === nextProps;
})