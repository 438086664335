import React from 'react';
import { Col, Row } from "react-bootstrap";
import MyAutocomplete from "../../../components/MyAutocomplete";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import Editor from '../../../components/editor/Editor';

export default function VisitsInformation(props) {
    const { users, visit, setVisit, visitTypes, user, predefPatient } = props;
    
    const states = ["Anul·lada", "Realitzada + pendent de pagament", "Realitzada + pagada"]

    const handleChange = (element) => (event) => {
		if (event.target.value === " ") return;
			setVisit({
				...visit,
				[element]: event.target.value,
			});
	};

    const handleChangeEditor = (element, new_body) => {
        setVisit({
            ...visit,
            [element]: new_body
        })
    }

    return (
        <>
            {(predefPatient && user)? (<h3>Paciente: {user?.name} {user?.surname}</h3>) : (
                <>
                    <MyAutocomplete
                        options={users}
                        getOptionLabel={(option) =>
                            option.name+' '+option.surname
                        }
                        value={
                            users?.find(
                                (x) => x._id === visit?.user
                            ) || null
                        }
                        onChange={(event, selected) => {
                            setVisit({
                                ...visit,
                                user: selected?._id,
                            });
                        }}
                        placeholder="Select patient"
                        label={"Patient name"}
                    />
                </>
            )}
            
            <Row>
                <Col sm={3}>
                    <TextField
                    id={`visitDate`}
                    label="Date"
                    value={visit?.visitDate}
                    onChange={handleChange('visitDate')}
                    InputLabelProps={{
                        shrink: true
                    }}
                    margin="normal"
                    variant="standard"
                    type='datetime-local'
                    required
                    />
                </Col>
                <Col sm={9}>
                    <MyAutocomplete
                        options={visitTypes}
                        getOptionLabel={(option) => option?.fullName?.es}
                        value={
                            visitTypes?.find(
                                (x) => x._id === visit?.visitType?._id
                            ) || null
                        }
                        onChange={(event, selected) => {
                            setVisit({
                                ...visit,
                                visitType: selected
                            });
                        }}
                        placeholder="Select visit type"
                        label={"Visit type"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <MyAutocomplete
                        options={states}
                        getOptionLabel={(option) => option}
                        value={
                            states?.find(
                                (x) => x === visit?.visitState
                            ) || null
                        }
                        onChange={(event, selected) => {
                            setVisit({
                                ...visit,
                                visitState: selected
                            });
                        }}
                        placeholder="Select visit state"
                        label={"Visit state"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextField
                    id={`notes`}
                    label="Notes"
                    value={visit?.notes}
                    onChange={handleChange('notes')}
                    InputLabelProps={{
                        shrink: true
                    }}
                    margin="normal"
                    variant="standard"
                    type='text'
                    required
                    multiline
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <TextField
                        id={`progress`}
                        label="Progress"
                        value={(visit?.progress) || ""}
                        onChange={handleChange("progress")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                        variant="standard"
                        placeholder="Enter progress..."
                        multiline
                        rows={1}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <TextField
                        id={`planOfAction`}
                        label="Plan of action"
                        value={(visit?.planOfAction) || ""}
                        onChange={handleChange("planOfAction")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                        variant="standard"
                        placeholder="Enter plan of action..."
                        multiline
                        rows={1}
                    />
                </Col>
            </Row>
            <Editor
                disabled={false}
                body={visit?.patientReport}
                setBody={(new_body) => {
                    if (new_body !== '') handleChangeEditor("patientReport", new_body);
                }}
                className="max-height"
                placeholder={"Patient report..."}
                name="Patient report"
            />
            <br />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={visit?.active}
                        onChange={() =>
                            setVisit({
                                ...visit,
                                active: !visit.active,
                            })
                        }
                        name="checkActive"
                    />
                }
                label="Active"
            />
        </>
    )
}