import React, { useState, useEffect } from 'react'
import { AppBar, Tabs, Tab } from '@material-ui/core'

function ExerciseTabLock({ exerciseTabContent }) {

	const [tab, setTab] = useState(0)

	const handleChangeTabs = async (_, newValue) => {
		setTab(newValue)
	}

	return (
		<>
			<AppBar position="static" color="default" key="appbar">
				<Tabs
					value={tab}
					onChange={handleChangeTabs}
					variant="scrollable"
					scrollButtons="auto"
					key="tabs"
				>
					<Tab
						key="TAB1"
						label="EXERCISE" />
					<Tab
						key="TAB2"
						label="FEEDBACK" />
				</Tabs>
			</AppBar>
			<div key={`TABCONTENT${tab}`}>
				{exerciseTabContent[tab]}
			</div>
		</>
	)
}

export default ExerciseTabLock
